<template>
	<div class="txt">
		<div class="head">
			<div style="width: 25%;height: 100%;">
				
			</div>
			<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<div>
					悠哉鸭
				</div>
				<div style="height: 60%;font-size: 20px;display: flex;justify-content: center;padding: 5px;">
					®️
				</div>
				<div>
					颐养云智慧终端
				</div>
			</div>
			<div style="width: 25%;height: 100%;font-size: 28px;display: flex;align-items: center;justify-content: center;line-height: 30px;">
				<div>
					<div style="width: 100%;display: flex;align-items: center;justify-content: center;">
						{{day}}
					</div>
					<div style="width: 100%;display: flex;align-items: center;justify-content: center;">
						{{time}}
					</div>
				</div>
			</div>
		</div>
		<div class="information" v-if="isBinding">
			<div style="width: 100%;height: 100%;display: flex;">
				<div style="width: 20%;height: 100%;display: flex;align-items: center;justify-content: center;" @click="getContacts">
					<div v-if="oldData.customerPic">
						<img :src="oldData.customerPic" style="width: 100px;height: 100px;border-radius: 50px;" />
					</div>
				</div>
				<div style="width: 3%;height: 100%;">
					
				</div>
				<div style="width: 53%;height: 100%;display: flex;align-items: center;justify-content: left;" @click="getContacts">
					<div style="width: 100%;height: 60%;">
						<div style="width: 100%;height: 50%;display: flex;align-items: center;justify-content: left;">
							<div style="font-size: 30px;">
								{{oldData.customerName || ''}}
							</div>
							<div style="width: 130px;font-size: 25px;display: flex;align-items: center;justify-content: center;background: linear-gradient(to right, #FBAB27, #FEE554);color: #B1664D;border-radius: 17px;margin-left: 20px;" v-if="oldData.auditItemTemplateName">
								{{oldData.auditItemTemplateName}}
							</div>
						</div>
						<div style="width: 100%;height: 50%;font-size: 22px;display: flex;align-items: center;justify-content: left;">
							<div>
								<!-- {{(weatherInfo.TodayTemp || '') + '&nbsp;&nbsp;&nbsp;' + (weatherInfo.WeatherInfo || '')}} -->
								{{'服务到期时间：' + (oldData.serviceExpirationDate || '无')}}
							</div>
						</div>
					</div>
				</div>
				<div style="width: 22%;height: 100%;display: flex;align-items: center;justify-content: center;position: relative;">
					<div style="width: 40px;height: 40px;border-radius: 20px;background-color: #fff;position: absolute;top: 0px;right: 0px;display: flex;align-items: center;justify-content: center;" v-if="pendingCount != 0">
						<div style="width: 36px;height: 36px;border-radius: 18px;background-color: red;display: flex;align-items: center;justify-content: center;">
							<div style="font-size: 20px;">
								{{pendingCount}}
							</div>
						</div>
					</div>
					<img src="../../img/alarmLogin.png"  @click="information()" />
				</div>
				<div style="width: 22%;height: 100%;display: flex;align-items: center;justify-content: center;">
					<img src="../../img/SOS.png" @click="getPopup(2)" />
				</div>
			</div>
		</div>
		<div style="width: 100%;height: 34%;font-size: 22px;padding: 10px 20px;" v-if="isBinding">
			<div style="width: 100%;height: 100%;background-color: #fff;border-radius: 25px;">
				<!-- <div style="width: 100%;height: 12%;display: flex;align-items: center;justify-content: flex-end;">
					<div style="width: 140px;height: 100%;background-color: #3F86FF;color: #fff;display: flex;align-items: center;justify-content: center;border-radius: 0 25px 0 15px;">
						基本信息
					</div>
				</div> -->
				<div style="width: 100%;height: 1%;">
					
				</div>
				<div style="width: 100%;height: 25%;">
					<div style="width: 94%;height: 100%;margin-left: 3%;display: flex;align-items: center;justify-content: left;">
						<div style="width: 16%;height: 100%;">
							{{'重点防范：'}}
						</div>
						<div style="width: 84%;height: 100%;">
							<div style="width: 100%;height: 50%;display: flex;align-items: center;justify-content: left;">
								<div v-for="(item, index) in oldData.taboos" style="min-width: 100px;height: 30px;background-color: #FFE5C2;color: #EF6C2C;margin-right: 10px;font-size: 20px;border-radius: 15px;display: flex;align-items: center;justify-content: center;" v-if="index < 5">
									<div style="min-width: 100px;height: 100%;display: flex;align-items: center;justify-content: center;padding: 5px;">
										{{item}}
									</div>
								</div>
							</div>
							<div style="width: 100%;height: 50%;display: flex;align-items: center;justify-content: left;">
								<div v-for="(item, index) in oldData.taboos" style="min-width: 100px;height: 30px;background-color: #FFE5C2;color: #EF6C2C;margin-right: 10px;font-size: 20px;border-radius: 15px;display: flex;align-items: center;justify-content: center;" v-if="index > 5">
									<div style="min-width: 100px;height: 100%;display: flex;align-items: center;justify-content: center;padding: 5px;">
										{{item}}
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
				<div style="width: 100%;height: 40%;">
					<div style="width: 94%;height: 100%;margin-left: 3%;display: flex;align-items: center;justify-content: left;">
						<div style="width: 16%;height: 100%;">
							{{'注意事项：'}}
						</div>
						<div style="width: 84%;height: 100%;align-items: center;justify-content: left;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 5;overflow: hidden;">
							{{oldData.note || '无'}}
						</div>
					</div>
				</div>
				<div style="width: 100%;height: 33%;">
					<div style="width: 94%;height: 100%;margin-left: 3%;display: flex;align-items: center;justify-content: left;">
						<div style="width: 16%;height: 100%;">
							{{'服务人员：'}}
						</div>
						<div style="width: 84%;height: 100%;display: flex;align-items: center;justify-content: left;">
							<div style="width: 33.3%;height: 100%;" @click="supportWorker" v-if="oldData.supportWorkerPic">
								<div style="width: 100%;height: 60%;display: flex;align-items: center;justify-content: center;">
									<img :src="oldData.supportWorkerPic" style="width: 70px;height: 70px;border-radius: 35px;" />
								</div>
								<div style="width: 100%;height: 40%;display: flex;align-items: center;justify-content: center;">
									<span>
										{{oldData.supportWorkerName || '无'}}
									</span>
									<span style="color: #DDDCDC;margin-left: 5px;">
										{{'|'}}
									</span>
									<span style="color: #79818D;margin-left: 5px;">
										{{'责任护理员'}}
									</span>
								</div>
							</div>
							<div style="width: 33.3%;height: 100%;" @click="responsibleNurse">
								<div style="width: 100%;height: 60%;display: flex;align-items: center;justify-content: center;">
									<img :src="oldData.responsibleNursePic" style="width: 70px;height: 70px;border-radius: 35px;" v-if="oldData.responsibleNursePic" />
								</div>
								<div style="width: 100%;height: 40%;display: flex;align-items: center;justify-content: center;">
									<span>
										{{oldData.responsibleNurseName || '无'}}
									</span>
									<span style="color: #DDDCDC;margin-left: 5px;">
										{{'|'}}
									</span>
									<span style="color: #79818D;margin-left: 5px;">
										{{'责任护士'}}
									</span>
								</div>
							</div>
							<div style="width: 33.3%;height: 100%;" @click="getPhone(oldData.responsibleSalesPhone, 2)">
								<div style="width: 100%;height: 60%;display: flex;align-items: center;justify-content: center;">
									<img :src="oldData.responsibleSalesPic" style="width: 70px;height: 70px;border-radius: 35px;" v-if="oldData.responsibleSalesPic" />
								</div>
								<div style="width: 100%;height: 40%;display: flex;align-items: center;justify-content: center;">
									<span>
										{{oldData.responsibleSalesName || '无'}}
									</span>
									<span style="color: #DDDCDC;margin-left: 5px;">
										{{'|'}}
									</span>
									<span style="color: #79818D;margin-left: 5px;">
										{{'养老管家'}}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="width: 100%;height: 4%;display: flex;color: #fff;" v-if="isBinding">
			<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: left;">
				<div style="margin-left: 30px;font-size: 30px;">
					<b>
						{{'健康数据'}}
					</b>
				</div>
			</div>
			<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: flex-end;">
				<div style="margin-right: 30px;font-size: 20px;" @click="health">
					{{'更多'}}
					<img src="../../img/ARR.png" />
				</div>
			</div>
		</div>
		<div class="health" v-if="isBinding">
			<div style="width: 55%;height: 100%;padding: 10px 20px;">
				<div class="recentPlan">
					<div style="width: 100%;height: 40%;display: flex;align-items: center;">
						<div style="width: 100%;height: 75%;display: flex;">
							<div style="width: 50%;height: 100%;">
								<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: left;">
									<div style="margin-left: 30px;font-size: 28px;">
										实时数据
									</div>
								</div>
							</div>
							<div style="width: 50%;height: 100%;">
								<div style="width: 100%;height: 50%;display: flex;align-items: center;justify-content: flex-end;">
									<div style="margin-right: 30px;font-size: 20px;">
										{{realTime}}
										
									</div>
								</div>
								<div style="width: 100%;height: 50%;display: flex;align-items: center;justify-content: flex-end;">
									<div style="margin-right: 30px;font-size: 20px;">
										单位：次/分钟
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style="width: 100%;height: 60%;background-color: #5d98fe;border-radius: 35px;font-size: 25px;display: flex;align-items: center;">
						<div style="width: 33.3%;height: 100%;">
							<div style="width: 100%;height: 70%;display: flex;align-items: center;justify-content: center;">
								<div style="width: 60px;height: 60px;background-color: #9EC1FF;border-radius: 10px;display: flex;align-items: center;justify-content: center;font-size: 30px;">
									{{heartRate}}
								</div>
							</div>
							<div style="width: 100%;height: 30%;display: flex;justify-content: center;font-size: 20px;">
								心率
							</div>
						</div>
						<div style="width: 33.3%;height: 100%;">
							<div style="width: 100%;height: 70%;display: flex;align-items: center;justify-content: center;">
								<div style="width: 60px;height: 60px;background-color: #9EC1FF;border-radius: 10px;display: flex;align-items: center;justify-content: center;font-size: 30px;">
									{{respiratoryRate}}
								</div>
							</div>
							<div style="width: 100%;height: 30%;display: flex;justify-content: center;font-size: 20px;">
								呼吸率
							</div>
						</div>
						<div style="width: 33.3%;height: 100%;">
							<div style="width: 100%;height: 70%;display: flex;align-items: center;justify-content: center;">
								<div style="width: 60px;height: 60px;background-color: #9EC1FF;border-radius: 10px;display: flex;align-items: center;justify-content: center;font-size: 28px;">
									<img src="../../img/offline.png" v-if="bedState === '离线'" />
									<img src="../../img/outBed.png" v-if="bedState === '离床'" />
									<img src="../../img/Bed.png" v-if="bedState === '在床'" />
								</div>
							</div>
							<div style="width: 100%;height: 30%;display: flex;justify-content: center;font-size: 20px;">
								{{bedState}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="todayPlan" v-if="isBinding">
				<div style="width: 100%;height: 100%;background-color: #fff;border-radius: 20px;" id="healthLine">
					<!-- <div style="width: 100%;height: 100%;">
						
					</div> -->
				</div>
			</div>
		</div>
		<!-- <div style="width: 100%;height: 4%;display: flex;color: #fff;" v-if="isBinding">
			<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: left;">
				<div style="margin-left: 30px;font-size: 30px;">
					<b>
						{{'服务计划'}}
					</b>
				</div>
			</div>
			<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: flex-end;">
				<div style="margin-right: 30px;font-size: 20px;" @click="sign(2)">
					{{'查看更多'}}
					<img src="../../img/ARR.png" />
				</div>
			</div>
		</div> -->
		<div style="width: 100%;height: 12%;padding: 10px 20px;" v-if="isBinding">
			<div class="alarmLog">
				<div style="width: 100%;height: 50%;display: flex;">
					<div style="width: 80%;height: 100%;display: flex;align-items: center;justify-content: left;padding-left: 30px;">
						<div style="font-size: 25px;color: #767E8B;">
							{{"今日服务计划"}}
						</div>
						&nbsp;&nbsp;
						<div style="font-size: 35px;color: #3F86FF;">
							{{todayPlanList.planCount || 0}}
						</div>
						&nbsp;&nbsp;&nbsp;
						<div style="font-size: 35px;color: #767E8B;">
							|
						</div>
						&nbsp;&nbsp;&nbsp;
						<div style="font-size: 25px;color: #767E8B;">
							已完成服务
						</div>
						&nbsp;&nbsp;
						<div style="font-size: 35px;color: #3F86FF;">
							{{todayPlanList.finshCount || 0}}
						</div>
					</div>
					<div style="width: 20%;height: 100%;display: flex;align-items: center;justify-content: flex-end;">
						<div style="margin-right: 15px;font-size: 20px;" @click="sign(2)">
							{{'更多'}}
							<img src="../../img/ARR.png" />
						</div>
					</div>
				</div>
				<div style="width: 100%;height: 50%;">
					<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;color: #4A8DFF;">
						<div style="width: 35%;height: 80%;border: 1px solid #D3E4FF;background-color: #EDF4FF;border-radius: 10px;display: flex;align-items: center;justify-content: center;margin-right: 15%;" @click="getPopup(0)">
							<img src="../../img/signIn.png" style="width: 25px;height: 25px;margin-right: 10px;" />
							{{'服务签到'}}
						</div>
						<div style="width: 35%;height: 80%;border: 1px solid #D3E4FF;background-color: #EDF4FF;border-radius: 10px;display: flex;align-items: center;justify-content: center;" @click="getPopup(1)">
							<img src="../../img/signOut.png" style="width: 25px;height: 25px;margin-right: 10px;" />
							{{'签退'}}
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="module" v-if="isBinding">
			<div style="width: 33.3%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<div style="width: 80%;height: 80%;border-radius: 10px;background-color: #fff;border: 1px solid #80DECD;" @click="service()">
					<div style="width: 100%;height: 60%;display: flex;align-items: center;justify-content: center;">
						<img src="../../img/Housekeeping.png" style="width: 40px;" />
					</div>
					<div style="width: 100%;height: 40%;display: flex;align-items: center;justify-content: center;">
						服务资讯
					</div>
				</div>
			</div>
			<div style="width: 33.3%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<div style="width: 80%;height: 80%;border-radius: 10px;background-color: #fff;border: 1px solid #91BBFF;" @click="homeCare()">
					<div style="width: 100%;height: 60%;display: flex;align-items: center;justify-content: center;">
						<img src="../../img/information.png" style="width: 40px;" />
					</div>
					<div style="width: 100%;height: 40%;display: flex;align-items: center;justify-content: center;">
						养老服务购买入口
					</div>
				</div>
			</div>
			
			<div style="width: 33.3%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<div style="width: 80%;height: 80%;border-radius: 10px;background-color: #fff;border: 1px solid #F9D6AE;" @click="elderlyCare()">
					<div style="width: 100%;height: 60%;display: flex;align-items: center;justify-content: center;">
						<img src="../../img/university.png" style="width: 40px;" />
					</div>
					<div style="width: 100%;height: 40%;display: flex;align-items: center;justify-content: center;">
						养老资讯
					</div>
				</div>
			</div>
			
			<!-- <div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<a-button class="signIn" @click="getPopup(0)">
					{{'上门签到'}}
				</a-button>
			</div>
			<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<a-button class="signOut" @click="getPopup(1)">
					{{'上门签退'}}
				</a-button>
			</div> -->
		</div>
		<div class="foot" v-if="isBinding">
			<div style="width: 35%;">
				{{'设备ID：' + this.deviceId}}
			</div>
			<!-- <div style="margin-left: 15px;">
				{{'设备ID：' + this.deviceId}}
			</div> -->
			<div style="width: 65%;display: flex;align-items: center;justify-content: flex-end;">
				{{'地址：' + oldData.homeAddress}}
			</div>
		</div>
		<div style="width: 100%;height: 93%;display: flex;align-items: center;justify-content: center;" v-if="!isBinding">
			<div>
				<div class="device-qrcode">
					<div id="qrcode" class="qrcode"></div>
				</div>
				<div style="width: 100%;height: 150px;">
					<div style="width: 100%;height: 50%;color: #fff;text-align: 25px;display: flex;align-items: center;justify-content: center;">
						{{'设备Id：' + deviceId}}
					</div>
					<div style="width: 100%;height: 50%;color: #fff;text-align: 30px;display: flex;align-items: center;justify-content: center;">
						{{'设备未绑定客户！'}}
					</div>
				</div>
			</div>
			
		</div>
		<a-modal v-model="visible" :footer="null" :centered="true" @cancel="handleOk" :title="promptType === 2 ? '发起报警' : '员工刷卡'">
		    <template>
				<div style="width: 100%;height: 80px;display: flex;align-items: center;justify-content: center;font-size: 40px;">
					<p v-if="promptType === 2 && countdown != 0">
						{{'将在' + countdown + '秒后发出SOS报警'}}
					</p>
					<p v-if="promptType === 2 && countdown === 0">
						{{'正在发送SOS报警'}}
					</p>
					<p v-if="promptType != 2">
						{{promptList[promptType]}}
					</p>
				</div>
				<div style="width: 100%;height: 50px;display: flex;align-items: center;justify-content: center;font-size: 20px;" v-if="promptType != 2">
					<a-input v-model="NFC" style="width: 60%;height: 90%;" @keyup.enter="sign" />
				</div>
				<div style="width: 100%;height: 100px;display: flex;align-items: center;justify-content: center;margin-top: 10px;">
					<a-button class="cancellation" @click="handleOk">
						{{'取消'}}
					</a-button>
				</div>
			</template>
		</a-modal>
		<a-modal v-model="alarmLogVisible" :centered="true" title="报警信息详情">
			<div style="width: 100%;height: 300px;font-size: 30px;">
				<div style="width: 100%;height: 50px;">
					{{'类型：' + alarmLogData.issueTypeName}}
				</div>
				<div style="width: 100%;height: 50px;white-space: nowrap;overflow: hidden;">
					{{'内容：' + alarmLogData.description}}
				</div>
				<div style="width: 100%;height: 50px;">
					{{'时间：' + alarmLogData.discoveryTime}}
				</div>
				<div style="width: 100%;height: 50px;">
					{{'设备：' + alarmLogData.otDeviceName}}
				</div>
				<div style="width: 100%;height: 50px;">
					{{'状态：' + alarmLogData.issueProgressName}}
				</div>
				<div style="width: 100%;height: 50px;white-space: nowrap;overflow: hidden;">
					{{'位置：' + alarmLogData.homeAddress}}
				</div>
			</div>
		    <template slot="footer">
				<div style="width: 100%; height: 100px;display: flex;align-items: center;justify-content: center;">
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
						<a-button class="cancellation" @click="alarmLogCancel">
							{{'返回'}}
						</a-button>
					</div>
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;" v-if="alarmLogData.issueProgress === 0">
						<a-button class="confirm" @click="notes">
							{{'取消报警'}}
						</a-button>
					</div>
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;" v-if="alarmLogData.issueProgress === 50">
						<a-button class="confirm" @click="notes">
							{{'处理完成'}}
						</a-button>
					</div>
				</div>
			</template>
		</a-modal>
		<a-modal v-model="notesVisible" :centered="true" :title="progress === 0 ? '取消报警' : '关闭报警'">
			<div style="width: 100%;height: 150px;font-size: 30px;display: flex;align-items: center;justify-content: center;">
				<a-textarea v-model="alarmLogData.closeRemark" placeholder="请输入关闭备注" style="width: 80%;height: 80%;font-size: 25px;" v-if="progress != 0"></a-textarea>
				<a-radio-group @change="onChange" v-else>
				    <a-radio :style="radioStyle" v-for="item in CancelList" :value="item.id">
						{{item.title}}
				    </a-radio>
				</a-radio-group>
			</div>
		    <template slot="footer">
				<div style="width: 100%; height: 100px;display: flex;">
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
						<a-button class="cancellation" @click="Cancellation">
							{{'返回'}}
						</a-button>
					</div>
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
						<a-button class="confirm" @click="Confirm(2)">
							{{'确认'}}
						</a-button>
					</div>
				</div>
			</template>
		</a-modal>
		<a-modal v-model="isPhone" :centered="true" :footer="null" :closable="false">
			<div style="width: 100%;height: 100px;display: flex;align-items: center;justify-content: center;" v-if="oldData.responsibleSalesPic">
				<img :src="oldData.responsibleSalesPic" style="width: 90px;height: 90px;border-radius: 45px;" />
			</div>
			<div style="width: 100%;height: 50px;display: flex;align-items: center;justify-content: left;font-size: 30px;">
				<span style="margin-left: 15px;">
					{{(phoneType === 1 ?'责任护士：' : '养老管家：') + (phoneType === 1 ? oldData.responsibleNurseName : oldData.responsibleSalesName)}}
				</span>
			</div>
			<div style="width: 100%;min-height: 50px;display: flex;align-items: center;justify-content: left;font-size: 30px;" v-if="phoneType === 1">
				<span style="margin-left: 15px;">
					{{'护理技能：' + oldData.responsibleNursingSkills}}
				</span>
			</div>
			<div style="width: 100%;height: 50px;display: flex;align-items: center;justify-content: left;font-size: 30px;" v-if="phoneType === 1">
				<span style="margin-left: 15px;">
					{{'从业年限：' + (oldData.responsibleYearsOfEmployment ? (oldData.responsibleYearsOfEmployment + '年') : '无')}}
				</span>
			</div>
			<div style="width: 100%;height: 50px;display: flex;align-items: center;justify-content: left;font-size: 30px;">
				<span style="margin-left: 15px;">
					{{'联系电话：' + (phone || '未配置')}}
				</span>
			</div>
			<div style="width: 100%;height: 50px;display: flex;align-items: center;justify-content: left;font-size: 30px;">
				<span style="margin-left: 15px;">
					{{'监督电话：' + (phone || '未配置')}}
				</span>
			</div>
			<div style="width: 100%; height: 50px;display: flex;align-items: center;justify-content: center;">
				<div style="width: 50%;display: flex;align-items: center;justify-content: center;">
					<a-button class="cancellation" @click="phoneCancel">
						{{'确认'}}
					</a-button>
				</div>
			</div>
		</a-modal>
		<a-modal v-model="iscontact" :centered="true" :footer="null" :closable="false">
			<div style="width: 100%;height: 60px;border-bottom: 1px solid #E5E5E5;display: flex;align-items: center;justify-content: center;font-size: 28px;color: #3F86FF;">
				联系人员
			</div>
			<div style="width: 100%;height: 120px;font-size: 25px;display: flex;align-items: center;justify-content: left;" v-for="item in contactList">
				<div style="width: 100%;height: 80%;border-bottom: 1px solid #E5E5E5;padding: 10px;">
					<div style="width: 100%;height: 50%;display: flex;">
						<div style="width: 50%; height: 100%;display: flex;align-items: center;justify-content: left;">
							{{item.name}}
						</div>
						<div style="width: 50%; height: 100%;display: flex;align-items: center;justify-content: flex-end;font-size: 20px;color: #A3A8B5">
							{{item.relationship || ''}}
						</div>
					</div>
					<div style="width: 100%;height: 50%;display: flex;">
						<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: left;font-size: 20px;color: #A3A8B5">
							<img src="../../img/phone.png" style="width: 25px;height: 25px;" />
							{{item.phoneNumber || '无'}}
						</div>
						<div style="width: 50%; height: 100%;display: flex;align-items: center;justify-content: flex-end;font-size: 20px;color: #A3A8B5">
							{{item.isDefault ? '紧急联系人' : ''}}
						</div>
					</div>
				</div>
			</div>
			<div style="width: 100%; height: 80px;display: flex;align-items: center;justify-content: center;">
				<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
					<a-button class="cancelPhone" @click="contactCancel">
						{{'确认'}}
					</a-button>
				</div>
			</div>
		</a-modal>
		<a-modal v-model="isHomeCare" :centered="true" :footer="null" :closable="false">
			<div style="width: 100%;height: 350px;display: flex;align-items: center;justify-content: center;font-size: 30px;">
				<img src="../../img/homeCare.png" style="width: 300px;height: 300px" />
			</div>
			<div style="width: 100%;font-size: 30px;text-align: center">
				扫码了解服务详情
			</div>
		</a-modal>
	</div>
</template>

<script>
import { badSender } from "@/utils/nativeUtils";
import { developUrl, produceUrl } from "@/utils/constant";
import axios from "axios";
import moment from "moment";
import { Toast } from "vant"
import QRCode from 'qrcodejs2';
import people from '../../img/people.png';
import echarts from 'echarts';
let _this;
	
export default {
	name: "home",
	components: {
		    
	},
	data() {
		return {
			deviceId: this.$route.query.deviceId,
			tenantId: undefined,
			day: undefined,
			time: undefined,
			oldData: {},
			todayPlanList: [
				
			],
			recentPlanList: [
				
			],
			pendingCount: 0,
			processingCount: 0,
			alarmLogList: [
				
			],
			
			tomorrow: undefined,
			NFC: undefined,
			visible: false,
			alarmLogVisible: false,
			alarmLogData: {},
			notesVisible: false,
			promptType: undefined,
			promptList: {
				0: '请刷员工卡进行签到',
				1: '请刷员工卡进行签退'
			},
			countdown: 5,
			timer: undefined,
			oldTimer: undefined,
			todayTimer: undefined,
			recentTimer: undefined,
			tomorrowTimer: undefined,
			alarmLogTimer: undefined,
			healthTimer: undefined,
			LineTimer: undefined,
			progressList: [
				{name: '待确认', progress: 0},
				{name: '处理中', progress: 50},
				{name: '已关闭', progress: 100}
			],
			progress: 0,
			no: undefined,
			radioStyle: {
				display: 'block',
				height: '40px',
				lineHeight: '40px',
				fontSize: '25px'
			},
			CancelList: [
				{title: '确认在测试功能，长者无恙，请放心！', id: 0},
				{title: '确认是设备误报，长者无恙，请放心！', id: 1},
				{title: '我在现场，正在联系医院！', id: 2},
				{title: '我在附近，正在赶往现场！', id: 3}
			],
			city: undefined,
			weatherInfo: {},
			healthData: ['XT', 'HX', 'ZC'],
			heartRate: 0,
			respiratoryRate: 0,
			systolicPressure: 0,
			diastolicPressure: 0,
			realTime: undefined,
			bedState: undefined,
			healthList: ['XT', 'HX'],
			healthListTime: [],
			healthListData: [],
			isPhone: false,
			phone: undefined,
			phoneType: 1,
			bloodPressureTime: undefined,
			isBinding: false,
			isQrcode: true,
			contactList: [],
			iscontact: false,
			isHomeCare: false
		}
	},
	mounted() {
		window.onCardDataReceiver = function(hex) {
			let data = JSON.parse(hex);
			if(data.status) {
				if(_this.visible && _this.promptType != 2) {
					// if(data.result.length > 10) {
					// 	let binary = Number(data.result).toString(16);
					// 	let NFC = binary.substring(binary.length - 8, binary.length);
					// 	_this.NFC = parseInt(NFC, 16);
					// }else {
					// 	_this.NFC = data.result;
					// }
					// let binary = Number(data.result);
					let NFC = data.result.substring(data.result.length - 8, data.result.length);
					_this.NFC = parseInt(NFC, 16);
					// _this.NFC = data.result;
					// Toast(_this.NFC);
					Toast(_this.NFC);
					_this.sign();
				}
			}
		}
		
		this.getTime();
		// this.getCity();
		this.getTenantId();
	},
	created(){
		_this = this;
	},
	computed: {
		option1() {
			return {
				// title: {
				// 	text: '部门计划完成率',
				// 	y: '5%',
				// 	x: '10%',
				// 	textStyle: {
				// 		fontSize: '20px',
				// 		fontWeight: 'normal',
				// 		color: '#fff'
				// 	}
				// },
				grid: {
					top: '50',
					left: '35',
					right: '30',
					bottom: '30'
				},
				color: ['#3f86ff ', '#ff7e41'],
				// tooltip: {
				//   trigger: 'axis',
				//   formatter: '{b0}<br />{a0}：{c0}<br />{a1}：{c1}<br />{a2}：{c2}%'
				// },
				legend: {
					right: '5%',
					top: '5%',
					// textStyle: {
					// 	color: '#fff'
					// }
				},
				calculable: true,
				xAxis: [{
					type: 'category',
					data: this.healthListTime,
					splitLine: {
						show:false,
						// lineStyle: {
						//   color: ['#fff']
						// }
					},
					axisLabel: {
						// lineStyle: {
						//   color: '#fff'
						// },
						// color: '#fff',
						fontSize: 10
					}
				}],
				yAxis: [{
						type: 'value',
						name: "次/分钟",
						min: 0,
						axisLabel: {
							formatter: '{value}',
							// color: '#fff'
						},
						splitLine: {
							show:false,
							// lineStyle: {
							//   color: ['#fff']
							// }
						},
						// nameTextStyle: {
						// 	color: '#fff'
						// }
					}
				],
				series: this.healthListData,
			}
		}
	},
	methods: {
		getCity() {
			axios.get('http://tools.seekersoft.net/Srv/Base.svc/IpInfo?appcode=DSWP').then(res => {
				let data = JSON.parse(res.data.d);
				this.city = data.result.City;
				this.getWeather();
			}).catch(err => {
				
			})
		},
		
		getWeather() {
			axios.get('http://tools.seekersoft.net/Srv/Base.svc/Weather?appcode=DSWP&citycode=' + this.city).then(res => {
				this.weatherInfo = JSON.parse(res.data.d);
				console.log(this.weatherInfo)
			}).catch(err => {
				
			})
		},
		
		getTime() {
			let date = new Date();
			
			this.day = moment(date).format('YYYY-MM-DD');
			this.time = moment(date).format('HH:mm:ss');
			setTimeout(_this.getTime, 0);
		},
		
		getTenantId() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetTenantIdByDeviceId?&deviceId=' + this.deviceId).then(res => {
				if(res.data.success) {
					this.isBinding = true;
					this.tenantId = res.data.result;
					this.getOldData();
					this.getHealth();
					this.getTodayPlan();
					this.getTomorrow();
					// this.getRecentPlan();
					this.getAlarmLog();
					this.getLine();
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
				this.isBinding = false;
				if(this.isQrcode) {
					this.getQrcode();
				}
				setTimeout(function () {
					_this.getTenantId();
				},10000);
			})
		},
		
		getQrcode() {
			this.isQrcode = false;
			let qrcodeDiv = document.getElementById('qrcode');
			let qrcode = new QRCode(qrcodeDiv, {
		    text: this.deviceId,
		    width: 200, // 高度
		    height: 200, // 宽度
		    colorDark: '#000000', //前景色
		    colorLight: '#ffffff', //后景色
		    correctLevel: QRCode.CorrectLevel.H
		  });
		},
		
		getOldData() {
			clearTimeout(_this.oldTimer);
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetCustomerInfo?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId).then(res => {
				if(res.data.success) {
					let data = res.data.result;
					this.oldData = data;
					this.oldData.serviceExpirationDate = this.oldData.serviceExpirationDate ? moment(this.oldData.serviceExpirationDate).format('YYYY年MM月DD日') : "";
					if(this.oldData.taboos) {
						this.oldData.taboos = data.taboos.split(',');
					}
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
			this.oldTimer = setTimeout(function () {
				_this.getOldData();
			},60000 * 60);
		},
		
		getHealth() {
			clearTimeout(_this.healthTimer);
			for(let i = 0; i < this.healthData.length; i++) {
				axios.get(produceUrl + 'api/services/app/BedScreenApi/GetHealthDataByCodeForBedScreen?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId + '&code=' + this.healthData[i]).then(res => {
					if(res.data.success) {
						let data = res.data.result;
						if(this.healthData[i] == 'XT') {
							if(data) {
								this.heartRate = Number(data.value);
							}else {
								this.heartRate = 0;
							}
						}else if(this.healthData[i] == 'HX') {
							if(data) {
								this.respiratoryRate = Number(data.value);
							}else {
								this.respiratoryRate = 0;
							}
						}else if(this.healthData[i] == 'XYSSY') {
							if(data) {
								this.systolicPressure = Number(data.value);
								this.bloodPressureTime = data.creationTime ? moment(data.creationTime).format('MM月DD日 HH:mm') : "";
							}else {
								this.systolicPressure = 0;
							}
						}else if(this.healthData[i] == 'XYSZY') {
							if(data) {
								this.diastolicPressure = Number(data.value);
							}else {
								this.diastolicPressure = 0;
							}
						}else if(this.healthData[i] == 'ZC') {
							if(data) {
								this.bedState = data.value;
								this.realTime = data.lastOnlineTime ? moment(data.lastOnlineTime).format('MM月DD日 HH:mm') : "";
							}else {
								this.bedState = '离线';
							}
						}
					}
				}).catch(err => {
					Toast.fail(err.response.data.error.message);
				})
			}
			this.healthTimer = setTimeout(function () {
				_this.getHealth();
			},10000);
		},
		
		//心率和呼吸率折线图
		getLine() {
			clearTimeout(_this.LineTimer);
			this.healthListData = [];
			for(let i = 0; i < this.healthList.length; i++) {
				axios.get(produceUrl + 'api/services/app/BedScreenApi/GetHealthDataStsForBedScreen?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId + '&codes=' + this.healthList[i] + '&range=0').then(res => {
					if(res.data.success) {
						let data = res.data.result;
						console.log(data)
						this.healthListTime = data.xAlias;
						let item = {
							name: data.legend[0],
							type: 'line',
							data: data.series[0].data,
							label: {
								show: false,
								// color: '#fff',
								// textBorderWidth: '0',
								// formatter: function(data){
								// 	if(data.value>0) {
								// 		return data.value + '%'
								// 	}else {
								// 		return ""
								// 	}
								// }
							}
						};
						this.healthListData.push(item);
						if(this.healthList[i] === "HX") {
							this.charts();
						}
					}
				}).catch(err => {
					Toast.fail(err.response.data.error.message);
				})
			}
			// this.LineTimer = setTimeout(function () {
			// 	_this.getTodayPlan();
			// }, 10000);
		},
		
		charts() {
			this.myeChart = echarts.init(document.getElementById("healthLine"));
			this.myeChart.clear();
			
			this.myeChart.setOption(this.option1);
			
			this.myeChart.resize();
			window.addEventListener('resize', () => {
				this.myeChart.resize();
			})
		},
		
		//今日服务计划
		getTodayPlan() {
			clearTimeout(_this.todayTimer);
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetTodayServicePlanAndFinshService?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId).then(res => {
				if(res.data.success) {
					let data = res.data.result;
					this.todayPlanList = data;
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
			this.todayTimer = setTimeout(function () {
				_this.getTodayPlan();
			}, 10000);
		},
		
		//近期服务计划
		getRecentPlan() {
			clearTimeout(_this.recentTimer);
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetRecentServicePlan?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId).then(res => {
				if(res.data.success) {
					let data = res.data.result;
					this.recentPlanList = data;
					this.recentPlanList.map(item => {
						item.serviceDate = item.serviceDate ? moment(item.serviceDate).format(
							'YYYY-MM-DD HH:mm') : "";
					})
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
			this.recentTimer = setTimeout(function () {
				_this.getRecentPlan();
			}, 10000);
		},
		
		getTomorrow() {
			clearTimeout(_this.tomorrowTimer);
			let today = new Date();
			this.tomorrow = moment(today.getTime() + 1000 * 60 * 60 * 24).format('YYYY-MM-DD');
			this.tomorrowTimer = setTimeout(function () {
				_this.getTomorrow();
			}, 60000 * 60);
		},
		
		//异常信息
		getAlarmLog(progress) {
			clearTimeout(_this.alarmLogTimer);
			if(progress != undefined) {
				this.progress = progress;
			}
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetBedScreenCallThePoliceRecord?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId + '&progress=' + this.progress).then(res => {
				if(res.data.success) {
					let data = res.data.result;
					this.pendingCount = data.countList[0];
					this.processingCount = data.countList[1];
					this.alarmLogList = data.data;
					this.alarmLogList.map(item => {
						item.discoveryTime = item.discoveryTime ? moment(item.discoveryTime).format(
							'YYYY-MM-DD HH:mm:ss') : "";
					})
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
			this.alarmLogTimer = setTimeout(function () {
				_this.getAlarmLog();
			}, 10000);
		},
		
		//更新异常信息
		updateAlarms(item) {
			this.alarmLogData = item;
			this.alarmLogVisible = true;
		},
		
		onChange(e) {
			this.alarmLogData.closeRemark = this.CancelList[e.target.value].title;
		},
		
		Confirm(type) {
			let data = {
				tenantId: this.tenantId,
				deviceId: this.deviceId,
				no: this.NFC
			};
			if(type === 1) {
				data.isSignIn = true;
				data.no = this.no;
			}else {
				data.isSignIn = false;
				data.issueId = this.alarmLogData.id;
				data.closeRemark = this.alarmLogData.closeRemark;
				data.progress = 100;
			}
			axios.post(produceUrl + 'api/services/app/BedScreenApi/HandleCallThePolice', data).then(res => {
				if(res.data.success) {
					this.notesVisible = false;
					this.alarmLogVisible = false;
					if(data.progress === 100) {
						Toast.success("关闭成功");
					}
					this.getAlarmLog();
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		notes() {
			this.notesVisible = true;
		},
		
		Cancellation() {
			this.notesVisible = false;
		},
		
		alarmLogCancel() {
			this.alarmLogVisible = false;
		},
		
		phoneCancel() {
			this.isPhone = false;
		},
		
		getPhone(item, type) {
			this.phone = item;
			this.phoneType = type;
			if(item) {
				this.isPhone = true;
			}
		},
		
		//NFC刷卡弹窗
		getPopup(type) {
			this.visible = true;
			this.promptType = type;
			if(type == 2) {
				this.countdown = 5;
				this.timer = setInterval(function () {
					if(_this.countdown === 0) {
						clearTimeout(_this.timer);
						_this.getSOS();
						return;
					}
					_this.countdown -= 1;
				}, 1000);
			}
		},
		
		//SOS
		getSOS() {
			axios.post(produceUrl + 'api/services/app/BedScreenApi/SosCallThePolice?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId).then(res => {
				if(res.data.success) {
					this.visible = false;
					Toast.success("报警成功");
					this.progress = 0;
					this.getAlarmLog();
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		handleOk() {
			this.visible = false;
			this.promptType = undefined;
			clearTimeout(_this.timer);
		},
		
		sign() {
			this.visible = false;
			this.no = this.NFC;
			this.NFC = undefined;
			if(this.promptType === 0) {
				this.Confirm(1);
				this.promptType = undefined;
				this.$router.push('/signIn?deviceId=' + this.deviceId + '&NFC=' + this.no + '&tenantId=' + this.tenantId);
			}else if(this.promptType === 1) {
				this.promptType = undefined;
				this.$router.push('/signOut?deviceId=' + this.deviceId + '&NFC=' + this.no + '&tenantId=' + this.tenantId);
			}else {
				this.$router.push('/tabulation?deviceId=' + this.deviceId + '&tenantId=' + this.tenantId);
			}
		},
		
		health() {
			this.$router.push('/health?id=' + this.oldData.id);
		},
		
		information() {
			this.$router.push('/information?deviceId=' + this.deviceId + '&tenantId=' + this.tenantId);
		},
		
		supportWorker() {
			this.$router.push('/supportWorker?deviceId=' + this.deviceId + '&tenantId=' + this.tenantId);
		},
		
		responsibleNurse() {
			this.$router.push('/responsibleNurse?deviceId=' + this.deviceId + '&tenantId=' + this.tenantId);
		},
		
		prompt() {
			Toast('此功能正在开发中！');
		},
		
		getContacts() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetCustomerContactsListForBedScreen?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId).then(res => {
				if(res.data.success) {
					let data = res.data.result;
					this.contactList = data;
					this.iscontact = true;
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		contactCancel() {
			this.iscontact = false;
		},
		
		service() {
			this.$router.push('/serviceItems?tenantId=' + this.tenantId);
		},
		
		elderlyCare() {
			this.$router.push('/elderlyCareInformation?tenantId=' + this.tenantId);
		},
		
		homeCare() {
			this.isHomeCare = true;
		}
	}
}
</script>

<style lang="less">
	
	.ant-radio {
	    top: -5px
	}
</style>

<style scoped>
	* {
		margin: 0;
		padding: 0;
	}
	body,html {
		margin: 0;
		padding: 0;
		font-size: 20px;
	}
	.txt {
		width: 100%;
		height: 100%;
		font-size: 20px;
		/* background-image: url('../../img/home1.png'); */
		background: #1b2f5d;
		-webkit-touch-callout:none;
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	.button:active {
		background-color: #00aaff;
	}
	.head {
		width: 100%;
		height: 7%;
		font-size: 35px;
		color: #fff;
		/* background-color:rgba(227, 227, 227, 0.5); */
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.information {
		width: 100%;
		height: 13%;
		color: #fff;
		font-size: 40px;
	}
	.health {
		width: 100%;
		height: 19%;
		font-size: 30px;
		display: flex;
	}
	.todayPlan {
		width: 45%;
		height: 100%;
		padding: 10px 20px;
	}
	.todayPlanTable {
		width: 94%;
		height: 80%;
		margin-left: 3%;
		overflow-y:auto;
		scrollbar-width: none; /* Firefox 64 */
	}
	.recentPlan {
		width: 100%;
		height: 100%;
		background-color: #4b8dfe;
		border-radius: 25px;
		color: #fff
	}
	.alarmLog {
		width: 100%;
		height: 100%;
		background-color: #fff;
		/* background-image: url('../../img/alarmLogBG.png'); */
		/* background-size: 100% 100%; */
		/* padding: 10px 20px; */
		border-radius: 25px;
		/* background-clip: content-box; */
	}
	.module {
		width: 100%;
		height: 8%;
		display: flex;
	}
	.foot {
		width: 100%;
		height: 3%;
		color: #d3d3d3;
		display: flex;
		align-items: center;
	}
	.informationPiece {
		margin-left: 30px;
	}
	.signIn {
		width: 80%;
		height: 80%;
		font-size: 40px;
		border-radius: 30px;
		color: #fff;
		background-image: url('../../img/button.png');
	}
	
	.signOut {
		width: 80%;
		height: 80%;
		font-size: 40px;
		border-radius: 30px;
		color: #599efe;
		background-color: #edf3ff;
		border: 1px solid #599efe;
	}
	
	.confirm {
		width: 80%;
		height: 60%;
		font-size: 30px;
		border-radius: 30px;
		color: #fff;
		background-image: url('../../img/button.png');
	}
	
	.cancellation {
		width: 100%;
		height: 60%;
		font-size: 30px;
		border-radius: 30px;
		color: #599efe;
		background-color: #edf3ff;
		border: 1px solid #599efe;
	}
	
	.cancelPhone {
		width: 100%;
		height: 80%;
		font-size: 30px;
		border-radius: 10px;
		color: #599efe;
		background-color: #edf3ff;
		border: 1px solid #599efe;
	}
	
	.alarmLogInformation {
		width: 100%;
		height: 80%;
		overflow-y: scroll;
	}
	
	.alarmLogInformation::-webkit-scrollbar {
		display: none;
	}
	
	/* .servicePlan {
		overflow-y: scroll;
	}
	
	.servicePlan::-webkit-scrollbar {
		display: none;
	} */
	
	.progress {
		width: 33.3%;
		height: 100%;
		color: #000;
		font-size: 20px;
	}
	
	.todayPlanText {
		width: 100%;
		height: 75%;
		overflow-y: scroll;
	}
	
	.todayPlanText::-webkit-scrollbar {
		display: none;
	}
	
	.device-qrcode {
	  width: 100%;
	  height: 250px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	}
	.qrcode {
	  background-color: #fff;
	  padding: 6px;
	}
	.state1 {
		background-color: #9EC1FF;
	}
	.state2 {
		background-color: #ffc926;
	}
	.state3 {
		background-color: #00b300;
	}
</style>