<template>
	<div class="txt">
		<div class="head">
			<div class="headLift" @click="Return">
				<div style="margin-left: 15px;">
					返回
				</div>
			</div>
			<div class="headCenter">
				报警记录
			</div>
			<div class="headRight">
				
			</div>
		</div>
		<div class="Body">
			<div style="width: 100%;height: 60px;display: flex;align-items: center;">
				<div style="width: 90%;height: 100%;border-radius: 60px;background-color: #fff;display: flex;margin-left: 5%;white-space: nowrap;overflow: hidden;">
					<div style="width: 33.3%;height: 60px;display: flex;align-items: center;justify-content: center;" v-for="(item , index) in progressList" @click="click(item)">
						<div style="width: 97%;height: 54px;border-radius: 60px;display: flex;align-items: center;justify-content: center;font-size: 30px;" v-if="progress != item.progress">
							{{item.name}}
							<div style="width: 32px;height: 32px;border-radius: 16px;background-color: red;margin-left: 5px;color: #fff;display: flex;align-items: center;justify-content: center;font-size: 20px;" v-if="item.count != 0">
								{{item.count}}
							</div>
						</div>
						<div style="width: 97%;height: 54px;border-radius: 60px;background-color: #4198FF;display: flex;align-items: center;justify-content: center;font-size: 30px;color: #fff;" v-else>
							{{item.name}}
							<div style="width: 32px;height: 32px;border-radius: 16px;background-color: red;margin-left: 5px;color: #fff;display: flex;align-items: center;justify-content: center;font-size: 20px;" v-if="item.count != 0">
								{{item.count}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="width: 100%;height: 15px;">
				
			</div>
			<div class="task">
				<div style="width: 90%;">
					<div class="card" v-for="item in alarmLogList" @click="updateAlarms(item)">
						<div style="width: 100%;height: 70px;display: flex;">
							<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: left;font-size: 22px;color: #697281;margin-left: 20px;">
								{{item.discoveryTime}}
							</div>
							<div style="width: 50%;height: 100%;display: flex;justify-content: flex-end;">
								<div style="width: 100px;height: 40px;background-color: #FEF7F0;margin-right: 20px;border-radius: 0 0 10px 10px;display: flex;align-items: center;justify-content: center;font-size: 18px;color: #FB793F;" v-if="progress === 0">
									{{'未处理'}}
								</div>
								<div style="width: 100px;height: 40px;background-color: #E6FBF2;margin-right: 20px;border-radius: 0 0 10px 10px;display: flex;align-items: center;justify-content: center;font-size: 18px;color: #1BC53B;" v-if="progress === 50">
									{{'处理中'}}
								</div>
								<div style="width: 100px;height: 40px;background-color: #E6FBF2;margin-right: 20px;border-radius: 0 0 10px 10px;display: flex;align-items: center;justify-content: center;font-size: 18px;color: #1BC53B;" v-if="progress === 100">
									{{'已处理'}}
								</div>
							</div>
						</div>
						<div style="width: calc(100% - 40px);height: 1px;border-top: 1px solid #E3E3E3 ;margin-left: 20px;display: flex;">
							
						</div>
						<div style="width: 100%;height: 149px;display: flex;" v-if="progress != 100">
							<div style="width: 50%;height: 149px;">
								<div style="width: 100%;height: 90px;display: flex;align-items: center;justify-content: left;">
									<div style="margin-left: 20px;font-size: 35px;">
										{{item.issueTypeName}}
									</div>
								</div>
								<div style="width: 100%;height: 59px;display: flex;justify-content: left;">
									<div style="margin-left: 20px;font-size: 25px;color: #A3A8B5;">
										{{item.otDeviceName}}
									</div>
								</div>
							</div>
							<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: flex-end;" v-if="item.duration != 0">
								<img src="../../img/exclamationPoint.png" style="width: 50px;" />
								<div style="margin-right: 20px; color: #FF5129;margin-left: 10px;font-size: 30px;">
									{{'已持续' + item.duration}}
								</div>
							</div>
						</div>
						<div style="width: 100%;height: 209px;display: flex;" v-else>
							<div style="width: 70%;height: 149px;">
								<div style="width: 100%;height: 90px;display: flex;align-items: center;justify-content: left;">
									<div style="margin-left: 20px;font-size: 35px;">
										{{item.issueTypeName}}
									</div>
								</div>
								<div style="width: 100%;height: 60px;display: flex;justify-content: left;">
									<div style="margin-left: 20px;font-size: 25px;color: #A3A8B5;">
										{{'处理时间 ' + item.closeTime}}
									</div>
								</div>
								<div style="width: 100%;height: 60px;display: flex;justify-content: left;">
									<div style="margin-left: 20px;font-size: 25px;color: #A3A8B5;">
										{{'处理说明 ' + (item.remark || '无')}}
									</div>
								</div>
								<!-- <div style="width: 100%;height: 59px;display: flex;justify-content: left;">
									<div style="margin-left: 20px;font-size: 25px;color: #A3A8B5;">
										{{'处理人 ' + (item.respName || '')}}
									</div>
								</div> -->
							</div>
							<div style="width: 30%;height: 100%;display: flex;justify-content: flex-end;" v-if="item.duration != 0">
								<div style="margin-right: 20px; color: #DDDEE0;margin-left: 10px;font-size: 30px;">
									{{'持续' + item.duration}}
								</div>
							</div>
						</div>
					</div>
					<div style="width: 100%;height: 200px;display: flex;align-items: center;justify-content: center;font-size: 30px;" v-if="alarmLogList.length === 0">
						<span v-if="progress === 0">
							{{'无待确认报警记录'}}
						</span>
						<span v-if="progress === 50">
							{{'无处理中报警记录'}}
						</span>
						<span v-if="progress === 100">
							{{'无已关闭报警记录'}}
						</span>
					</div>
				</div>
			</div>
		</div>
		<a-modal v-model="alarmLogVisible" :centered="true" title="报警信息详情">
			<div style="width: 100%;height: 300px;font-size: 30px;">
				<div style="width: 100%;height: 50px;">
					{{'类型：' + alarmLogData.issueTypeName}}
				</div>
				<div style="width: 100%;height: 50px;white-space: nowrap;overflow: hidden;">
					{{'内容：' + alarmLogData.description}}
				</div>
				<div style="width: 100%;height: 50px;">
					{{'时间：' + alarmLogData.discoveryTime}}
				</div>
				<div style="width: 100%;height: 50px;">
					{{'设备：' + alarmLogData.otDeviceName}}
				</div>
				<div style="width: 100%;height: 50px;">
					{{'状态：' + alarmLogData.issueProgressName}}
				</div>
				<div style="width: 100%;height: 50px;white-space: nowrap;overflow: hidden;">
					{{'位置：' + alarmLogData.homeAddress}}
				</div>
			</div>
		    <template slot="footer">
				<div style="width: 100%; height: 100px;display: flex;align-items: center;justify-content: center;">
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;" v-if="alarmLogData.issueProgress != 0">
						<a-button class="cancellation" @click="alarmLogCancel">
							{{'返回'}}
						</a-button>
					</div>
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;" v-if="alarmLogData.issueProgress === 0">
						<a-button class="cancellation" @click="notes">
							{{'取消报警'}}
						</a-button>
					</div>
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;" v-if="alarmLogData.issueProgress === 0">
						<a-button class="confirm" @click="Confirm(1)">
							{{'已确认'}}
						</a-button>
					</div>
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;" v-if="alarmLogData.issueProgress === 50">
						<a-button class="confirm" @click="notes">
							{{'处理完成'}}
						</a-button>
					</div>
				</div>
			</template>
		</a-modal>
		<a-modal v-model="notesVisible" :centered="true" :title="progress === 0 ? '取消报警' : '关闭报警'">
			<div style="width: 100%;height: 150px;font-size: 30px;display: flex;align-items: center;justify-content: center;">
				<a-textarea v-model="alarmLogData.closeRemark" placeholder="请输入关闭备注" style="width: 80%;height: 80%;font-size: 25px;" v-if="progress != 0"></a-textarea>
				<a-radio-group @change="onChange" v-else>
				    <a-radio :style="radioStyle" v-for="item in CancelList" :value="item.id">
						{{item.title}}
				    </a-radio>
				</a-radio-group>
			</div>
		    <template slot="footer">
				<div style="width: 100%; height: 100px;display: flex;">
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
						<a-button class="cancellation" @click="Cancellation">
							{{'返回'}}
						</a-button>
					</div>
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
						<a-button class="confirm" @click="Confirm(2)">
							{{'确认'}}
						</a-button>
					</div>
				</div>
			</template>
		</a-modal>
	</div>
</template>

<script>
import { developUrl, produceUrl } from "@/utils/constant";
import axios from "axios";
import moment from "moment";
import { Toast } from "vant"
let _this;
	
export default {
	name: "information",
	components: {
		    
	},
	data() {
		return {
			deviceId: this.$route.query.deviceId,
			tenantId: this.$route.query.tenantId,
			progressList: [
				{name: '待确认', progress: 0, count: 0},
				{name: '处理中', progress: 50, count: 0},
				{name: '已关闭', progress: 100, count: 0}
			],
			progress: 0,
			alarmLogList: [],
			alarmLogVisible: false,
			alarmLogData: {},
			notesVisible: false,
			radioStyle: {
				display: 'block',
				height: '40px',
				lineHeight: '40px',
				fontSize: '25px'
			},
			CancelList: [
				{title: '确认在测试功能，长者无恙，请放心！', id: 0},
				{title: '确认是设备误报，长者无恙，请放心！', id: 1},
				{title: '我在现场，正在联系医院！', id: 2},
				{title: '我在附近，正在赶往现场！', id: 3}
			],
		}
	},
	mounted() {
		this.getTime();
		this.getData();
	},
	created(){
		_this = this;
	},
	computed: {
		
	},
	methods: {
		getTime() {
			this.date = new Date();
			setTimeout(_this.getTime, 0);
		},
		
		getData() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetBedScreenCallThePoliceRecord?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId + '&progress=' + this.progress).then(res => {
				if(res.data.success) {
					let data = res.data.result;
					// this.pendingCount = data.countList[0];
					// this.processingCount = data.countList[1];
					this.progressList[0].count = data.countList[0];
					this.progressList[1].count = data.countList[1];
					this.alarmLogList = data.data;
					this.alarmLogList.map(item => {
						if(this.progress != 100) {
							let timestamp = this.date.getTime() - new Date(item.discoveryTime).getTime();
							let duration = 0;
							if((timestamp / 1000) > 1 && (timestamp / 1000) < 60) {
								duration = Math.round((timestamp / 1000) * 10) / 10 + '秒';
							}else if((timestamp / 60000) > 1 && (timestamp / (60 * 60 *1000)) < 1) {
								duration = Math.round((timestamp / (60 * 1000)) * 10) / 10 + '分钟';
							}else if((timestamp / (60 * 60 *1000)) > 1 && (timestamp / (60 * 60 * 24 *1000)) < 3) {
								duration = Math.round((timestamp / (60 * 60 * 1000)) * 10) / 10 + '小时';
							}else if((timestamp / (60 * 60 * 1000)) > 3) {
								duration = Math.round((timestamp / (60 * 60 * 1000 * 24)) * 10) / 10 + '天';
							}
							item.duration = duration;
						}else {
							let timestamp = new Date(item.closeTime).getTime() - new Date(item.discoveryTime).getTime();
							let duration = 0;
							if((timestamp / 1000) > 1 && (timestamp / 1000) < 60) {
								duration = Math.round((timestamp / 1000) * 10) / 10 + '秒';
							}else if((timestamp / 60000) > 1 && (timestamp / (60 * 60 *1000)) < 1) {
								duration = Math.round((timestamp / (60 * 1000)) * 10) / 10 + '分钟';
							}else if((timestamp / (60 * 60 *1000)) > 1 && (timestamp / (60 * 60 * 24 *1000)) < 3) {
								duration = Math.round((timestamp / (60 * 60 * 1000)) * 10) / 10 + '小时';
							}else if((timestamp / (60 * 60 * 1000)) > 3) {
								duration = Math.round((timestamp / (60 * 60 * 1000 * 24)) * 10) / 10 + '天';
							}
							item.duration = duration;
						}
						item.discoveryTime = item.discoveryTime ? moment(item.discoveryTime).format(
							'YYYY年MM月DD日 HH:mm:ss') : "";
						item.closeTime = item.closeTime ? moment(item.closeTime).format(
							'YYYY年MM月DD日 HH:mm:ss') : "";
					});
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		click(item) {
			this.progress = item.progress;
			this.getData();
		},
		
		//更新异常信息
		updateAlarms(item) {
			this.alarmLogData = item;
			this.alarmLogVisible = true;
		},
		
		onChange(e) {
			this.alarmLogData.closeRemark = this.CancelList[e.target.value].title;
		},
		
		Confirm(type) {
			let data = {
				tenantId: this.tenantId,
				deviceId: this.deviceId,
				no: this.NFC
			};
			if(type === 1) {
				data.isSignIn = true;
				data.issueId = this.alarmLogData.id;
				data.progress = 50;
			}else {
				data.isSignIn = false;
				data.issueId = this.alarmLogData.id;
				data.closeRemark = this.alarmLogData.closeRemark;
				data.progress = 100;
			}
			axios.post(produceUrl + 'api/services/app/BedScreenApi/HandleCallThePolice', data).then(res => {
				if(res.data.success) {
					this.notesVisible = false;
					this.alarmLogVisible = false;
					if(data.progress === 100) {
						Toast.success("关闭成功");
					}
					this.getData();
				}
			}).catch(err => {
				console.log(err)
				Toast.fail(err.response.data.error.message);
			})
		},
		
		notes() {
			this.notesVisible = true;
		},
		
		Cancellation() {
			this.notesVisible = false;
		},
		
		alarmLogCancel() {
			this.alarmLogVisible = false;
		},
		
		Return() {
			this.$router.back()
		}
	}
}
</script>

<style lang="less" scoped>
	* {
		margin: 0;
		padding: 0;
	}
	body,html {
		margin: 0;
		padding: 0;
		font-size: 20px;
	}
	.txt {
		width: 100%;
		height: 100%;
		background-image: url('../../img/home.png');
		-webkit-touch-callout:none;
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	.head {
		width: 100%;
		height: 7%;
		font-size: 35px;
		color: #fff;
		background-color:rgba(227, 227, 227, 0.5);
		display: flex;
	}
	.headLift {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: left;
	}
	.headCenter {
		width: 40%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.headRight {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Body {
		width: 100%;
		height: 90%;
		margin-top: 10px;
		// overflow-y:auto;
	}
	
	// .Body::-webkit-scrollbar {
	// 	display: none;
	// }
	
	.foot {
		width: 100%;
		height: 10%;
		color: #d3d3d3;
		display: flex;
	}
	
	.task {
		width: 100%;
		height: 990px;
		display: flex;
		justify-content: center;
		overflow-y:auto;
	}
	.task::-webkit-scrollbar {
		display: none;
	}
	.card {
		width: 100%;
		// height: 100%;
		background-color: #fff;
		padding: 10px 10px;
		background-clip: content-box;
		border-radius: 30px;
	}
	
	.progress {
		width: 33.3%;
		height: 100%;
		color: #000;
		font-size: 20px;
	}
	
	.confirm {
		width: 80%;
		height: 60%;
		font-size: 30px;
		border-radius: 30px;
		color: #fff;
		background-image: url('../../img/button.png');
	}
	
	.cancellation {
		width: 80%;
		height: 60%;
		font-size: 30px;
		border-radius: 30px;
		color: #599efe;
		background-color: #edf3ff;
		border: 1px solid #599efe;
	}
</style>