<template>
	<div class="txt">
		<div class="head">
			<div class="headLift" @click="Return">
				<div style="margin-left: 15px;">
					返回
				</div>
			</div>
			<div class="headCenter">
				护士信息
			</div>
			<div class="headRight">
				
			</div>
		</div>
		<div class="Body">
			<div style="width: 100%;height: 30%;">
				<div style="width: 100%;height: 40%;display: flex;">
					<div style="width: 20%;height: 100%;display: flex;align-items: center;justify-content: center;">
						<div v-if="supportWorkerData.supportWorkerPic">
							<img :src="supportWorkerData.supportWorkerPic" style="width: 120px;height: 120px;border-radius: 60px;" />
						</div>
					</div>
					<div style="width: 80%;height: 100%;padding-left: 30px;">
						
						<div style="width: 100%;height: 30%;;display: flex;align-items: center;justify-content: left;font-size: 25px;">
							<span>
								{{supportWorkerData.supportWorkerName}}
							</span>
							<span style="font-size: 20px;margin-left: 20px;color: #79818D;">
								{{supportWorkerData.supportWorkerAge + '岁'}}
							</span>
						</div>
						<div style="width: 100%;height: 30%;;display: flex;align-items: center;justify-content: left;font-size: 25px;">
							<div>
								{{'身高 ' + (supportWorkerData.height || '')}}
							</div>
							<div>
								{{'体重 ' + (supportWorkerData.weight || '')}}
							</div>
						</div>
						<div style="width: 100%;height: 40%;;font-size: 20px;">
							<div style="width: 100%;height: 100%;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
								{{'个人介绍：本人性格善良，待人诚实，有爱心，做事积极认真，细心，有责任心，有良好的沟通能力，有高级护理员证、健康证。'}}
							</div>
						</div>
						<!-- <div style="width: 100%;height: 40%;display: flex;align-items: center;justify-content: left;margin-top: 30px;">
							<div style="font-size: 35px;">
								{{supportWorkerData.supportWorkerName}}
							</div>
							<div style="font-size: 25px;margin-left: 30px;">
								{{supportWorkerData.supportWorkerAge + '岁'}}
							</div>
						</div>
						<div style="width: 100%;height: 40%;display: flex;align-items: center;justify-content: left;font-size: 28px;">
							{{supportWorkerData.yearsOfEmployment}}
						</div> -->
					</div>
				</div>
				<div style="width: 100%;height: 10%;padding-left: 30px;">
					<span style="font-size: 20px;">
						{{'联系电话：' + (supportWorkerData.phoneNumber || '')}}
					</span>
				</div>
				<div style="width: 100%;height: 15%;padding-left: 30px;">
					<div style="width: 100%;height: 50%;display: flex;align-items: center;justify-content: left;">
						<img src="../../img/star.png" style="margin-right: 10px;width: 30px;height: 30px;" v-for="index of supportWorkerData.supportWorkerStarRating" />
						<img src="../../img/star1.png" style="margin-right: 10px;width: 30px;height: 30px;" v-for="index of (3 - supportWorkerData.supportWorkerStarRating)" />
						<span style="font-size: 20px;">
							{{(supportWorkerData.supportWorkerStarRating || 0) + '星'}}
						</span>
					</div>
				</div>
			</div>
			<div style="width: 100%;height: 68%;border-radius: 30px 30px 0 0;background-color: #fff;margin-top: 2%;">
				<div style="width: 100%;height: 30%;padding-left: 30px;">
					<div style="width: 100%;height: 15%;font-size: 30px;display: flex;align-items: center;justify-content: left;">
						工作经历
					</div>
					<div style="width: 100%;height: 45%;font-size: 25px;">
						{{supportWorkerData.WorkExperience || '暂无'}}
					</div>
					<div style="width: 100%;height: 10%;font-size: 25px;display: flex;align-items: center;justify-content: left;">
						{{'家庭情况：' + (supportWorkerData.familySituation || '暂无')}}
					</div>
					<div style="width: 100%;height: 30%;font-size: 25px;display: flex;align-items: center;justify-content: left;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
						{{'护理技能：' + (supportWorkerData.nursingSkills || '暂无')}}
					</div>
					<!-- <div style="width: 100%;height: 80px;font-size: 30px;display: flex;align-items: center;justify-content: left;">
						简介
					</div>
					<div style="width: 100%;height: calc(100% - 80px);font-size: 30px;display: flex;align-items: center;justify-content: left;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;overflow: hidden;">
						{{supportWorkerData.briefIntroduction || '暂无简介'}}
					</div> -->
				</div>
				<!-- <div style="width: 100%;height: 30%;padding-left: 30px;">
					<div style="width: 100%;height: 80px;font-size: 30px;display: flex;align-items: center;justify-content: left;">
						专业证件
					</div>
					<div style="width: 100%;height: calc(100% - 80px);display: flex;" class="certificate">
						<div style="width: 33.3%;height: 100%;" v-for="item in certificateList" v-if="certificateList.length > 0">
							<div style="width: 100%;height: 80%;display: flex;align-items: center;justify-content: center;">
								<img  :src="item.picpath + item.file[0].locName + item.file[0].fileType" style="width: 200px;height: 130px;"/>
							</div>
							<div style="width: 100%;height: 20%;display: flex;align-items: center;justify-content: center;font-size: 20px;">
								{{item.name}}
							</div>
						</div>
						<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 30px;" v-if="certificateList.length === 0">
							未上传证件
						</div>
					</div>
				</div> -->
				<!-- <div style="width: 100%;height: 40%;padding-left: 30px;">
					<div style="width: 100%;height: 80px;font-size: 30px;display: flex;align-items: center;justify-content: left;">
						评价记录
					</div>
					<div style="width: 100%;height: calc(100% - 80px);" class="record">
						<div style="width: 100%;height: 120px;" v-for="item in recordList">
							<div style="width: 100%;height: 70%;display: flex;">
								<div style="width: 10%;height: 100%;display: flex;align-items: center;justify-content: center;">
									<img src="../../img/logo.png" style="width: 70px;height: 70px;border-radius: 35px;" />
								</div>
								<div style="width: 50%;height: 100%;">
									<div style="font-size: 30px;">
										{{item.evaluationUser}}
									</div>
									<div style="font-size: 25px;">
										{{item.evaluationDate}}
									</div>
								</div>
								<div style="width: 40%;height: 100%;display: flex;align-items: center;justify-content: flex-end;">
									<img src="../../img/star.png" style="margin-right: 10px;width: 30px;height: 30px;" v-for="index of item.rating" />
									<img src="../../img/star1.png" style="margin-right: 10px;width: 30px;height: 30px;" v-for="index of (5 - item.rating)" />
								</div>
							</div>
							<div style="width: 100%;height: 30%;font-size: 25px;">
								{{item.content}}
							</div>
						</div>
						<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 30px;" v-if="recordList.length === 0">
							无评价记录
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<!-- <div class="foot">
			<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<a-button class="cancellation" @click="Return">
					{{'返回'}}
				</a-button>
			</div>
		</div> -->
	</div>
</template>

<script>
import { developUrl, produceUrl } from "@/utils/constant";
import axios from "axios";
import moment from "moment";
import { Toast } from "vant"
let _this;
	
export default {
	name: "supportWorker",
	components: {
		    
	},
	data() {
		return {
			deviceId: this.$route.query.deviceId,
			tenantId: this.$route.query.tenantId,
			supportWorkerData: {
				supportWorkerStarRating: 0
			},
			recordList: [],
			certificateList: []
		}
	},
	mounted() {
		this.getData();
	},
	created(){
		_this = this;
	},
	computed: {
		
	},
	methods: {
		getData() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetNurseInfo?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId).then(res => {
				if(res.data.success) {
					let data = res.data.result;
					this.supportWorkerData = data;
					if(this.supportWorkerData.supportWorkerStarRating < 1) {
						this.supportWorkerData.supportWorkerStarRating = 1;
					}else if(1 < this.supportWorkerData.supportWorkerStarRating < 2) {
						this.supportWorkerData.supportWorkerStarRating = 2;
					}else if(this.supportWorkerData.supportWorkerStarRating > 2) {
						this.supportWorkerData.supportWorkerStarRating = 3;
					}
					// this.getCertificate();
					// this.getRecord();
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		getCertificate() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetSkillInfoListByUserId?tenantId=' + this.tenantId +'&userid=' + this.supportWorkerData.supportWorker).then(res => {
				if(res.data.success) {
					let data = res.data.result;
					this.certificateList = data;
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		getRecord() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetEvaluationRecordList?tenantId=' + this.tenantId +'&userid=' + this.supportWorkerData.supportWorker).then(res => {
				if(res.data.success) {
					let data = res.data.result;
					// console.log(data)
					this.recordList = data;
					// console.log(this.recordList)
					this.recordList.map(item => {
						item.evaluationDate = item.evaluationDate ? moment(item.evaluationDate).format(
							'YYYY年MM月DD日') : "";
					})
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		Return() {
			this.$router.back()
		}
	}
}
</script>

<style lang="less" scoped>
	* {
		margin: 0;
		padding: 0;
	}
	body,html {
		margin: 0;
		padding: 0;
		font-size: 20px;
	}
	
	.txt {
		width: 100%;
		height: 100%;
		background-image: url('../../img/home.png');
		-webkit-touch-callout:none;
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	.head {
		width: 100%;
		height: 7%;
		font-size: 35px;
		color: #fff;
		background-color:rgba(227, 227, 227, 0.5);
		display: flex;
	}
	.headLift {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: left;
	}
	.headCenter {
		width: 40%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.headRight {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Body {
		width: 100%;
		height: 90%;
		margin-top: 10px;
		// overflow-y:auto;
	}
	
	// .Body::-webkit-scrollbar {
	// 	display: none;
	// }
	
	.foot {
		width: 100%;
		height: 10%;
		color: #d3d3d3;
		display: flex;
	}
	
	.task {
		overflow-y:auto;
	}
	.task::-webkit-scrollbar {
		display: none;
	}
	
	.certificate {
		overflow-x:auto;
	}
	.certificate::-webkit-scrollbar {
		display: none;
	}
</style>