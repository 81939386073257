const NativeActionConst = {
  badSender: "badSender",
  radarDataReceiver: "radarDataReceiver"
};

const NativeMethodMap = new Map();

Object.keys(NativeActionConst).forEach((key) => {
  NativeMethodMap.set(key, []);
});

/**
 * 创建promise并将成功回调加入队列
 * @param action
 * @param arg 额外参数
 * @returns {Promise<unknown>}
 */
function createPromise(action, ...arg) {
  return new Promise((resolve) => {
    const methods = NativeMethodMap.get(action);
    if (Array.isArray(methods)) {
      methods.push(resolve);
      NativeMethodMap.set(action, methods);
    } else {
      NativeMethodMap.set(action, [resolve]);
    }
    try {
      setupNativeMethods();
      window.JSInterface.callAndroid(action, arg.toString());
    } catch (e) {
      console.log(`createPromise error--`, JSON.stringify(e));
    }
  });
}

/**
 * 创建window挂载回调
 * @param action 回调里面要触发的方法标识
 * @returns {(function(*): void)|*}
 */
function createResolve(action) {
  return function (result) {
    let fn = () => {};
    const methods = NativeMethodMap.get(action);
    try {
      if (Array.isArray(methods)) {
        fn = methods.shift();
        if (fn) {
          // console.log(`action-${action}-`, result);
          if (result) {
            fn(JSON.parse(JSON.parse(JSON.stringify(result))));
          } else {
            fn();
          }
        }
      } else {
        console.error(`${action}方法未创建promise`);
      }
    } catch (e) {
      console.log("error---", action);
    }
  };
}

/**
 * 初始化挂在window的安卓方法
 */
export function setupNativeMethods() {
  window.onBadSender = createResolve(NativeActionConst.badSender);
  window.onRadarDataReceiver = createResolve(NativeActionConst.radarDataReceiver);
}

export function badSender(code, hex) {
  return createPromise(NativeActionConst.badSender, code, hex);
}

export function radarDataReceiver(offset, code) {
  return createPromise(NativeActionConst.radarDataReceiver, offset, code);
}
