<template>
	<div class="txt">
		<div class="head">
			<div class="headLift" @click="Return">
				<div style="margin-left: 15px;">
					返回
				</div>
			</div>
			<div class="headCenter">
				服务项目
			</div>
			<div class="headRight">
				
			</div>
		</div>
		<div class="Body" style="margin-top: 10px;">
			<div style="width: 100%;height: 60px;display: flex;align-items: center;">
				<div style="width: 90%;height: 100%;border-radius: 60px;background-color: #fff;display: flex;margin-left: 5%;" class="group">
					<div style="width: 180px;height: 60px;display: flex;align-items: center;justify-content: center;" v-for="(item , index) in grouping" @click="groupClick(item)">
						<div style="width: 171px;height: 54px;border-radius: 60px;display: flex;align-items: center;justify-content: center;font-size: 25px;margin-left: 7.5px;" v-if="groupId != item.id">
							{{item.name}}
						</div>
						<div style="width: 171px;height: 54px;border-radius: 60px;background-color: #4198FF;display: flex;align-items: center;justify-content: center;font-size: 25px;margin-left: 7.5px;color: #fff;" v-else>
							{{item.name}}
						</div>
					</div>
				</div>
			</div>
			<div class="task">
				<div style="width: 90%;height: 100%;">
					<div class="card" v-for="item in templateList" @click="create(item)" style="display: flex;">
						<div style="width: 15%;display: flex;align-items: center;justify-content: center;">
							<div style="width: 50px;height: 50px;background-color: #4198FF;display: flex;align-items: center;justify-content: center;color: #fff;font-size: 35px;">
								{{item.code}}
							</div>
						</div>
						<div style="width: 85%;height: 100%;">
							<div style="width: 100%;min-height: 100px;display: flex;align-items: center;font-size: 40px;">
								{{item.name}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="foot">
			<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<a-button class="cancellation" @click="Return">
					{{'取消'}}
				</a-button>
			</div>
			<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<a-button class="signIn" @click="signIn">
					{{'创建'}}
				</a-button>
			</div>
		</div> -->
	</div>
</template>

<script>
import { developUrl, produceUrl } from "@/utils/constant";
import axios from "axios";
import moment from "moment";
import { Toast, showLoadingToast } from "vant"
let _this;
	
export default {
	name: "create",
	components: {
		    
	},
	data() {
		return {
			deviceId: this.$route.query.deviceId,
			tenantId: this.$route.query.tenantId,
			NFC: this.$route.query.NFC,
			taskList: [],
			taskIdList: [],
			visible: false,
			grouping: [],
			groupId: undefined,
			templateList: [],
			templateId: undefined
		}
	},
	mounted() {
		this.getData();
		// this.getTemplate();
	},
	created(){
		_this = this;
	},
	computed: {
		
	},
	methods: {
		getData() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetAuditItemGroupListForBedScreen?tenantId=' + this.tenantId +'&scenesid=2').then(res => {
				if(res.data.success) {
					let data = res.data.result;
					this.grouping = data;
					this.grouping.map(item => {
						item.isSelect = false;
					});
					this.groupId = this.grouping[0].id;
					this.getTemplate();
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		groupClick(item) {
			if(!item.isSelect) {
				this.groupId = item.id;
			}
			this.getTemplate();
		},
		
		getTemplate() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetAuditItemListForBedScreen?tenantId=' + this.tenantId + '&auditItemGroupId=' + this.groupId).then(res => {
				if(res.data.success) {
					console.log(res.data.result)
					this.templateList = res.data.result;
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		create(item) {
			Toast.loading({
				message: '创建中...',
				forbidClick: true
			})
			let data = {
				tenantId: this.tenantId,
				deviceId: this.deviceId,
				no: this.NFC,
				auditItem: [item.id],
				remark: '111'
			};
			axios.post(produceUrl + 'api/services/app/BedScreenApi/CreateServiceProdForBedScreen', data).then(res => {
				if(res.data.success) {
					Toast.clear();
					Toast.success("创建成功");
					this.visible = false;
					this.getData();
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		Return() {
			this.$router.back()
		},
		
		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			);
		}
	}
}
</script>

<style lang="less">
	.ant-select {
		height: 50%;
		font-size: 25px !important;
		
		.ant-select-selection {
			height: 100%;
			
			.ant-select-selection__placeholder {
				height: 100%;
				line-height: 30px;
			}
			
			.ant-select-selection__rendered {
				height: 100%;
				
				 .ant-select-selection-selected-value {
					height: 100%;
					display: flex !important;
					align-items: center !important;
					justify-content: left !important;
				}
			}
		}
	}
	
	.ant-select-dropdown-menu-item {
	    line-height: 30px;
	}
	
	.my-check.ant-checkbox-wrapper {
	    font-size: 35px;
		
	    .ant-checkbox-inner {
	        width: 30px;
	        height: 30px;
	        font-size: 15px;
	
	        &::after {
	            top: 13px;
	            left: 6px;
	            width: 10px;
	            height: 20px;
	        }
	    }
	}
</style>

<style lang="less" scoped>
	* {
		margin: 0;
		padding: 0;
	}
	body,html {
		margin: 0;
		padding: 0;
		font-size: 20px;
	}
	.my-check.ant-checkbox-wrapper {
	    font-size: 35px;
		
	    .ant-checkbox-inner {
	        width: 30px;
	        height: 30px;
	        font-size: 15px;
	
	        &::after {
	            top: 13px;
	            left: 6px;
	            width: 10px;
	            height: 20px;
	        }
	    }
	}
	.txt {
		width: 100%;
		height: 100%;
		background-image: url('../../img/home.png');
		-webkit-touch-callout:none;
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	.head {
		width: 100%;
		height: 7%;
		font-size: 35px;
		color: #fff;
		background-color:rgba(227, 227, 227, 0.5);
		display: flex;
	}
	.headLift {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: left;
	}
	.headCenter {
		width: 40%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.headRight {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Body {
		width: 100%;
		height: 90%;
		// overflow-y:auto;
	}
	
	// .Body::-webkit-scrollbar {
	// 	display: none;
	// }
	
	.group {
		overflow-x:auto;
	}
	
	.group::-webkit-scrollbar {
		display: none;
	}
	
	.foot {
		width: 100%;
		height: 10%;
		color: #d3d3d3;
		display: flex;
	}
	.task {
		width: 100%;
		height: 990px;
		display: flex;
		justify-content: center;
		overflow-y:auto;
	}
	.task::-webkit-scrollbar {
		display: none;
	}
	.card {
		width: 100%;
		min-height: 120px;
		background-color: #fff;
		padding: 10px 10px;
		background-clip: content-box;
		border-radius: 30px;
	}
	
	.signIn {
		width: 80%;
		height: 60%;
		font-size: 40px;
		border-radius: 30px;
		color: #fff;
		background-image: url('../../img/button.png');
	}
	
	.cancellation {
		width: 80%;
		height: 60%;
		font-size: 40px;
		border-radius: 30px;
		color: #599efe;
		background-color: #edf3ff;
		border: 1px solid #599efe;
	}
	
	.create {
		width: 80%;
		height: 60%;
		font-size: 30px;
		border-radius: 30px;
		color: #fff;
		background-image: url('../../img/button.png');
	}
	
	.cancel {
		width: 80%;
		height: 60%;
		font-size: 30px;
		border-radius: 30px;
		color: #599efe;
		background-color: #edf3ff;
		border: 1px solid #599efe;
	}
	
	.cardtext {
		width: 95%;
		font-size: 25px;
		text-align: left;
		padding: 0 30px;
	}
</style>