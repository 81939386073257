import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'ant-design-vue/dist/antd.css';
import { Modal, Button, Input, Tag, Checkbox, Select, Icon, Radio } from 'ant-design-vue';
Vue.use(Modal);
Vue.use(Button);
Vue.use(Input);
Vue.use(Tag);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(Icon);
Vue.use(Radio);

import 'vant/lib/index.css';
import Vant, { Toast } from 'vant';
Vue.use(Vant);

Toast.setDefaultOptions({
  className: "zoom2"
});

Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
