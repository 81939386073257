<template>
	<div class="txt">
		<div class="head">
			<div class="headLift" @click="Return">
				<div style="margin-left: 15px;">
					返回
				</div>
			</div>
			<div class="headCenter">
				养老资讯
			</div>
			<div class="headRight">
				
			</div>
		</div>
		<div class="task">
			<div style="width: 100%;height: 8%;display: flex;">
				<div style="display: flex;align-items: center;justify-content: left;margin-left: 40px;font-size: 35px;">
					{{data.fullHead}}
				</div>
				<div style="display: flex;align-items: center;justify-content: left;margin-left: 40px;font-size: 25px;">
					{{data.releaseTime}}
				</div>
			</div>
			<div style="width: 100%;min-height: 92%;background-color: #fff;border-top-left-radius: 50px;">
				<div style="width: 90%;margin-left: 5%;" v-html="data.newsContent">
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { developUrl, produceUrl } from "@/utils/constant";
import axios from "axios";
import { Toast } from "vant";
import moment from "moment";
let _this;
	
export default {
	name: "informationDetails",
	components: {
		    
	},
	data() {
		return {
			tenantId: this.$route.query.tenantId,
			data: JSON.parse(this.$route.query.item)
		}
	},
	mounted() {
		
	},
	created(){
		_this = this;
		this.getData();
	},
	computed: {
		
	},
	methods: {
		getData() {
			console.log(this.data)
		},
		Return() {
			this.$router.back()
		}
	}
}
</script>

<style lang="less" scoped>
	* {
		margin: 0;
		padding: 0;
	}
	body,html {
		margin: 0;
		padding: 0;
		font-size: 20px;
	}
	
	.txt {
		width: 100%;
		height: 100%;
		background-image: url('../../img/home.png');
		-webkit-touch-callout:none;
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	.head {
		width: 100%;
		height: 7%;
		font-size: 35px;
		color: #fff;
		background-color:rgba(227, 227, 227, 0.5);
		display: flex;
	}
	.headLift {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: left;
	}
	.headCenter {
		width: 40%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.headRight {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Body {
		width: 100%;
		height: 90%;
		margin-top: 10px;
		// overflow-y:auto;
	}
	
	// .Body::-webkit-scrollbar {
	// 	display: none;
	// }
	
	.task {
		width: 100%;
		height: 93%;
		overflow-y:auto;
	}
	.task::-webkit-scrollbar {
		display: none;
	}
	
	.foot {
		width: 100%;
		height: 10%;
		color: #d3d3d3;
		display: flex;
	}
</style>