<template>
	<div class="txt">
		<div class="head">
			<div class="headLift" @click="Return">
				<div style="margin-left: 15px;">
					返回
				</div>
			</div>
			<div class="headCenter">
				服务任务列表
			</div>
			<div class="headRight">
				
			</div>
		</div>
		<div class="Body">
			<div class="task">
				<div style="width: 90%;" v-if="taskList.length > 0">
					<div class="card" v-for="item in taskList">
						<div style="width: 100%;height: 65px;display: flex;align-items: center;font-size: 35px;">
							<div style="margin-left: 30px;width: 65%;white-space: nowrap;overflow: hidden;">
								{{item.auditItemName}}
							</div>
							<div style="width: 35%;font-size: 20px;text-align: right;">
								<div style="margin-right: 30px;">
									{{'护理员：' +  (item.serviceUser || '无')}}
								</div>
							</div>
						</div>
						<div style="width: 100%;height: 50px;display: flex;align-items: center;font-size: 20px;">
							<div style="width: 40%;padding-left: 30px;">
								{{item.auditTaskStatus}}
							</div>
							<div style="width: 60%;text-align: right;padding-right: 30px;" v-if="item.finishTime">
								{{'完成时间：' + item.finishTime}}
							</div>
						</div>
						<div style="width: 100%;height: 1px;">
							<div style="width: 90%;height: 1px;background-color: #d8d8d8;margin-left: 30px;">
								
							</div>
						</div>
						<div class="cardtext" v-html="item.desc || '无详情信息'">
							
						</div>
					</div>
				</div>
				<div style="font-size: 50px;color: #fff;" v-else>
					没有服务任务
				</div>
			</div>
		</div>
		<div class="foot">
			<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<a-button class="cancellation" @click="Return">
					{{'返回'}}
				</a-button>
			</div>
		</div>
	</div>
</template>

<script>
import { developUrl, produceUrl } from "@/utils/constant";
import axios from "axios";
import moment from "moment";
import { Toast } from "vant"
let _this;
	
export default {
	name: "tabulation",
	components: {
		    
	},
	data() {
		return {
			deviceId: this.$route.query.deviceId,
			tenantId: this.$route.query.tenantId,
			NFC: this.$route.query.NFC,
			taskList: []
		}
	},
	mounted() {
		this.getData();
	},
	created(){
		_this = this;
	},
	computed: {
		
	},
	methods: {
		getData() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetTodayServicePlan?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId + '&isAll=true').then(res => {
				if(res.data.success) {
					let data = res.data.result;
					this.taskList = data;
					this.taskList.map(item => {
						item.finishTime = item.finishTime ? moment(item.finishTime).format('HH:mm') : "";
					})
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		Return() {
			this.$router.back()
		}
	}
}
</script>

<style lang="less">
	.ant-select {
		height: 50%;
		font-size: 25px !important;
		
		.ant-select-selection {
			height: 100%;
			
			.ant-select-selection__placeholder {
				height: 100%;
				line-height: 30px;
			}
			
			.ant-select-selection__rendered {
				height: 100%;
				
				 .ant-select-selection-selected-value {
					height: 100%;
					display: flex !important;
					align-items: center !important;
					justify-content: left !important;
				}
			}
		}
	}
	
	.ant-select-dropdown-menu-item {
	    line-height: 30px;
	}
	
	.my-check.ant-checkbox-wrapper {
	    font-size: 35px;
		
	    .ant-checkbox-inner {
	        width: 30px;
	        height: 30px;
	        font-size: 15px;
	
	        &::after {
	            top: 13px;
	            left: 6px;
	            width: 10px;
	            height: 20px;
	        }
	    }
	}
</style>

<style lang="less" scoped>
	* {
		margin: 0;
		padding: 0;
	}
	body,html {
		margin: 0;
		padding: 0;
		font-size: 20px;
	}
	.my-check.ant-checkbox-wrapper {
	    font-size: 35px;
		
	    .ant-checkbox-inner {
	        width: 30px;
	        height: 30px;
	        font-size: 15px;
	
	        &::after {
	            top: 13px;
	            left: 6px;
	            width: 10px;
	            height: 20px;
	        }
	    }
	}
	.txt {
		width: 100%;
		height: 100%;
		background-image: url('../../img/home.png');
		-webkit-touch-callout:none;
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	.head {
		width: 100%;
		height: 7%;
		font-size: 35px;
		color: #fff;
		background-color:rgba(227, 227, 227, 0.5);
		display: flex;
	}
	.headLift {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: left;
	}
	.headCenter {
		width: 40%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.headRight {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Body {
		width: 100%;
		height: 83%;
		overflow-y:auto;
	}
	
	.Body::-webkit-scrollbar {
		display: none;
	}
	
	.foot {
		width: 100%;
		height: 10%;
		color: #d3d3d3;
		display: flex;
	}
	.task {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.card {
		width: 100%;
		height: 100%;
		background-color: #fff;
		padding: 10px 10px;
		background-clip: content-box;
		border-radius: 30px;
	}
	
	.signIn {
		width: 80%;
		height: 60%;
		font-size: 40px;
		border-radius: 30px;
		color: #fff;
		background-image: url('../../img/button.png');
	}
	
	.cancellation {
		width: 80%;
		height: 60%;
		font-size: 40px;
		border-radius: 30px;
		color: #599efe;
		background-color: #edf3ff;
		border: 1px solid #599efe;
	}
	
	.create {
		width: 80%;
		height: 60%;
		font-size: 30px;
		border-radius: 30px;
		color: #fff;
		background-image: url('../../img/button.png');
	}
	
	.cancel {
		width: 80%;
		height: 60%;
		font-size: 30px;
		border-radius: 30px;
		color: #599efe;
		background-color: #edf3ff;
		border: 1px solid #599efe;
	}
	
	.cardtext {
		width: 95%;
		font-size: 25px;
		text-align: left;
		padding: 0 30px;
		color: #A3A8B5;
	}
</style>