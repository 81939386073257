<template>
	<div class="txt">
		<div class="head">
			<div class="headLift" @click="Return">
				<div style="margin-left: 15px;">
					返回
				</div>
			</div>
			<div class="headCenter">
				服务资讯
			</div>
			<div class="headRight">
				
			</div>
		</div>
		<div class="task">
			<!-- <img src="../../img/serviceItems.png" style="width: 100%;" /> -->
			<div style="width: 100%;" v-html="data.newsContent">
				
			</div>
		</div>
	</div>
</template>

<script>
import { developUrl, produceUrl } from "@/utils/constant";
import axios from "axios";
import { Toast } from "vant";
let _this;
	
export default {
	name: "serviceItems",
	components: {
		    
	},
	data() {
		return {
			tenantId: this.$route.query.tenantId,
			data: []
		}
	},
	mounted() {
		
	},
	created(){
		_this = this;
		this.getData();
	},
	computed: {
		
	},
	methods: {
		getData() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetElderlyCareInformation?&tenantId=' + this.tenantId + '&filterText=服务资讯展示信息维护').then(res => {
				if(res.data.success) {
					this.data = res.data.result[0];
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		Return() {
			this.$router.back()
		}
	}
}
</script>

<style lang="less" scoped>
	* {
		margin: 0;
		padding: 0;
	}
	body,html {
		margin: 0;
		padding: 0;
		font-size: 20px;
	}
	
	.txt {
		width: 100%;
		height: 100%;
		background-image: url('../../img/home.png');
		-webkit-touch-callout:none;
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	.head {
		width: 100%;
		height: 7%;
		font-size: 35px;
		color: #fff;
		background-color:rgba(227, 227, 227, 0.5);
		display: flex;
	}
	.headLift {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: left;
	}
	.headCenter {
		width: 40%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.headRight {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Body {
		width: 100%;
		height: 90%;
		margin-top: 10px;
		// overflow-y:auto;
	}
	
	// .Body::-webkit-scrollbar {
	// 	display: none;
	// }
	
	.task {
		width: 100%;
		height: 93%;
		overflow-y:auto;
	}
	.task::-webkit-scrollbar {
		display: none;
	}
	
	.foot {
		width: 100%;
		height: 10%;
		color: #d3d3d3;
		display: flex;
	}
</style>