import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/views/home/index.vue";
import signIn from "@/views/task/signIn.vue";
import signOut from "@/views/task/signOut.vue";
import tabulation from "@/views/task/tabulation.vue";
import create from "@/views/task/create.vue";
import Layout from "@/components/block.vue";
import health from "@/views/health/index.vue";
import information from "@/views/information/index.vue";
import supportWorker from "@/views/supportWorker/index.vue";
import responsibleNurse from "@/views/responsibleNurse/index.vue";
import serviceItems from "@/views/service/index.vue";
import elderlyCareInformation from "@/views/elderlyCareInformation/index.vue";
import informationDetails from "@/views/elderlyCareInformation/informationDetails.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "root",
		component: Layout,
		redirect: "home",
		children: [
			{
			  path: "home",
			  name: "home",
			  component: home,
				meta: {
				  title: 'home'
				}
			},
			{
			  path: "signIn",
			  name: "signIn",
			  component: signIn,
				meta: {
				  title: 'signIn'
				}
			},
			{
			  path: "signOut",
			  name: "signOut",
			  component: signOut,
				meta: {
				  title: 'signOut'
				}
			},
			{
			  path: "tabulation",
			  name: "tabulation",
			  component: tabulation,
				meta: {
				  title: 'tabulation'
				}
			},
			{
			  path: "create",
			  name: "create",
			  component: create,
				meta: {
				  title: 'create'
				}
			},
			{
			  path: "health",
			  name: "health",
			  component: health,
				meta: {
				  title: 'health'
				}
			},
			{
			  path: "information",
			  name: "information",
			  component: information,
				meta: {
				  title: 'information'
				}
			},
			{
			  path: "supportWorker",
			  name: "supportWorker",
			  component: supportWorker,
				meta: {
				  title: 'supportWorker'
				}
			},
			{
			  path: "responsibleNurse",
			  name: "responsibleNurse",
			  component: responsibleNurse,
				meta: {
				  title: 'responsibleNurse'
				}
			},
			{
			  path: "serviceItems",
			  name: "serviceItems",
			  component: serviceItems,
				meta: {
				  title: 'serviceItems'
				}
			},
			{
			  path: "elderlyCareInformation",
			  name: "elderlyCareInformation",
			  component: elderlyCareInformation,
				meta: {
				  title: 'elderlyCareInformation'
				}
			},
			{
			  path: "informationDetails",
			  name: "informationDetails",
			  component: informationDetails,
				meta: {
				  title: 'informationDetails'
				}
			},
		]
	}
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
