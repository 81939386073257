<template>
	<div class="txt">
		<div class="head">
			<div class="headLift" @click="Return">
				<div style="margin-left: 15px;">
					返回
				</div>
			</div>
			<div class="headCenter">
				请选择签退任务
			</div>
			<div class="headRight">
				
			</div>
		</div>
		<div class="Body">
			<div class="task">
				<div style="width: 90%;" v-model="taskIdList" v-if="taskList.length > 0">
					<div class="card" v-for="item in taskList" @click="chick(item)" style="display: flex;">
						<div style="width: 15%;height: 166px;display: flex;align-items: center;justify-content: center;">
							<div style="width: 50px;height: 50px;border-radius: 25px;border: 1px solid #4198FF;">
								<div style="width: 100%;height: 100%;background-color: #4198FF;border-radius: 25px;display: flex;align-items: center;justify-content: center;" v-if="item.isSelect">
									<img src="../../img/answer.png" style="width: 30px;" />
								</div>
							</div>
						</div>
						<div style="width: 85%;height: 100%;">
							<div style="width: 100%;display: flex;align-items: center;font-size: 40px;">
								{{item.auditItemName}}
							</div>
							<div style="width: 100%;height: 1px;">
								<div style="width: 90%;height: 1px;background-color: #d8d8d8;">
									
								</div>
							</div>
							<div class="cardtext" v-html="item.desc || '无详情信息'">
								<!-- {{item.desc || ''}} -->
							</div>
						</div>
					</div>
				</div>
				<div style="font-size: 50px;color: #fff;" v-else>
					没有服务任务
				</div>
			</div>
		</div>
		<div class="foot">
			<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<a-button class="cancellation" @click="Return">
					{{'返回'}}
				</a-button>
			</div>
			<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<a-button class="signOut" @click="signOut">
					{{'签退'}}
				</a-button>
			</div>
		</div>
		<a-modal v-model="visible" :centered="true" :footer="null" :closable="false">
			<div style="width: 100%;height: 100px;display: flex;align-items: center;justify-content: center;">
				<img src="../../img/love.png" style="width: 100px;" />
			</div>
			<div style="width: 100%;height: 50px;display: flex;align-items: center;justify-content: center;font-size: 20px;">
				{{'感谢你的辛苦付出，照护对象才有了更好的生活体验'}}
			</div>
			<div style="width: 100%; height: 100px;display: flex;align-items: center;justify-content: center;">
				<a-button class="cancellation" @click="Cancellation">
					{{'确认'}}
				</a-button>
			</div>
		    <!-- <template slot="footer">
				<div style="width: 100%; height: 100px;display: flex;">
					<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;">
						<a-button class="confirm" @click="Cancellation">
							{{'确认'}}
						</a-button>
					</div>
				</div>
			</template> -->
		</a-modal>
	</div>
</template>

<script>
import { developUrl, produceUrl } from "@/utils/constant";
import axios from "axios";
import moment from "moment";
import { Toast } from "vant"
let _this;
	
export default {
	name: "signOut",
	components: {
		    
	},
	data() {
		return {
			deviceId: this.$route.query.deviceId,
			tenantId: this.$route.query.tenantId,
			NFC: this.$route.query.NFC,
			taskList: [],
			taskIdList: [],
			visible: false
		}
	},
	mounted() {
		this.getData();
	},
	created(){
		_this = this;
	},
	computed: {
		
	},
	methods: {
		getData() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetServicePlanByNo?tenantId=' + this.tenantId +'&deviceId=' + this.deviceId + '&no=' + this.NFC + '&isSignIn=false').then(res => {
				if(res.data.success) {
					let data = res.data.result;
					this.taskList = data;
					this.taskList.map(item => {
						item.isSelect = false;
					})
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		chick(item) {
			if(this.taskIdList.includes(item.auditTaskId)) {
				this.taskIdList.splice(this.taskIdList.indexOf(item.auditTaskId), 1);
				this.taskList.map(items => {
					if(item.auditTaskId === items.auditTaskId) {
						item.isSelect = false;
					}
				})
			}else {
				this.taskIdList.push(item.auditTaskId);
				this.taskList.map(items => {
					if(item.auditTaskId === items.auditTaskId) {
						item.isSelect = true;
					}
				})
			}
		},
		
		signOut() {
			// if (this.taskIdList.length <= 0) {
			// 	Modal.warning({
			// 		title: '请至少选择一项！'
			// 	});
			// 	return;
			// }
			let data = this.taskIdList;
			axios.post(produceUrl + 'api/services/app/BedScreenApi/SignOut?tenantId=' + this.tenantId,this.taskIdList).then(res => {
				if(res.data.success) {
					this.visible = true;
					Toast.success("签退成功");
					this.Return();
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		
		Cancellation() {
			this.visible = false;
		},
		
		Return() {
			this.$router.back()
		}
	}
}
</script>

<style lang="less">
	.ant-modal-body {
		
	}
	.my-check.ant-checkbox-wrapper {
	    font-size: 35px;
		
	    .ant-checkbox-inner {
	        width: 30px;
	        height: 30px;
	        font-size: 15px;
	
	        &::after {
	            top: 13px;
	            left: 6px;
	            width: 10px;
	            height: 20px;
	        }
	    }
	}
</style>

<style scoped>
	* {
		margin: 0;
		padding: 0;
	}
	body,html {
		margin: 0;
		padding: 0;
		font-size: 20px;
	}
	.my-check.ant-checkbox-wrapper {
	    font-size: 25px;
		
	    .ant-checkbox-inner {
	        width: 30px;
	        height: 30px;
	        font-size: 15px;
	
	        &::after {
	            top: 13px;
	            left: 6px;
	            width: 10px;
	            height: 20px;
	        }
	    }
	}
	.txt {
		width: 100%;
		height: 100%;
		background-image: url('../../img/home.png');
		-webkit-touch-callout:none;
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	.button:active {
		background-color: #00aaff;
	}
	.head {
		width: 100%;
		height: 7%;
		font-size: 35px;
		display: flex;
		color: #fff;
		background-color:rgba(227, 227, 227, 0.5);
	}
	.headLift {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: left;
	}
	.headCenter {
		width: 40%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.headRight {
		width: 30%;
	}
	.Body {
		width: 100%;
		height: 83%;
		overflow-y:auto;
	}
	.Body::-webkit-scrollbar {
		display: none;
	}
	.foot {
		width: 100%;
		height: 10%;
		color: #d3d3d3;
		display: flex;
		align-items: center;
		text-align: right;
		justify-content: right;
	}
	.task {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.card {
		width: 100%;
		height: 100%;
		background-color: #fff;
		padding: 10px 10px;
		background-clip: content-box;
		border-radius: 30px;
	}
	
	.signOut {
		width: 80%;
		height: 60%;
		font-size: 40px;
		border-radius: 30px;
		color: #fff;
		background-image: url('../../img/button.png');
	}
	
	.cancellation {
		width: 80%;
		height: 60%;
		font-size: 30px;
		border-radius: 30px;
		color: #599efe;
		/* background-color: #edf3ff; */
		border: 1px solid #599efe;
	}
	
	.cardtext {
		width: 95%;
		height: 70%;
		font-size: 25px;
		text-align: left;
		overflow-y: scroll;
		padding: 0 30px 0 0;
		color: #A3A8B5;
	}
	
	.cardtext::-webkit-scrollbar {
		display: none;
	}
	
	.confirm {
		width: 80%;
		height: 60%;
		font-size: 30px;
		border-radius: 30px;
		color: #fff;
		background-image: url('../../img/button.png');
	}
</style>