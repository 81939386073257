<template>
	<div class="txt">
		<div class="head">
			<div class="headLift" @click="Return">
				<div style="margin-left: 15px;">
					返回
				</div>
			</div>
			<div class="headCenter">
				养老资讯
			</div>
			<div class="headRight">
				
			</div>
		</div>
		<div class="task">
			<div style="width: 100%;background-color: #fff;border-radius: 30px;">
				<div style="width: 100%;height: 190px;" v-for="(item, index) in data" @click="details(item)">
					<div style="width: 100%;height: 50px;font-size: 25px;padding-left: 30px;padding-right: 30px;color: #8993a5;">
						<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: left;border-bottom: 1px solid #dfdfdf;">
							{{item.releaseTime}}
						</div>
					</div>
					<div style="width: 100%;height: 50px;font-size: 25px;padding-left: 30px;padding-right: 30px;display: flex;align-items: center;justify-content: left;">
						{{item.fullHead}}
					</div>
					<div style="width: 100%;height: 90px;font-size: 20px;padding-left: 30px;padding-right: 30px;color: #8993a5;">
						<div style="width: 100%;height: 100%;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">
							{{item.subtitle}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { developUrl, produceUrl } from "@/utils/constant";
import axios from "axios";
import { Toast } from "vant";
import moment from "moment";
let _this;
	
export default {
	name: "elderlyCareInformation",
	components: {
		    
	},
	data() {
		return {
			tenantId: this.$route.query.tenantId,
			data: []
		}
	},
	mounted() {
		
	},
	created(){
		_this = this;
		this.getData();
	},
	computed: {
		
	},
	methods: {
		getData() {
			axios.get(produceUrl + 'api/services/app/BedScreenApi/GetElderlyCareInformation?&tenantId=' + this.tenantId).then(res => {
				if(res.data.success) {
					let data = res.data.result;
					console.log(res.data)
					let information = [];
					for(let i = 0; i < data.length; i++) {
						if(data[i].typeCode === "News") {
							data[i].releaseTime = data[i].releaseTime ? moment(data[i].releaseTime).format('YYYY-MM-DD') : "";
							information.push(data[i]);
						}
					}
					this.data = information;
				}
			}).catch(err => {
				Toast.fail(err.response.data.error.message);
			})
		},
		details(item) {
			this.$router.push('/informationDetails?tenantId=' + this.tenantId + '&item=' + JSON.stringify(item));
		},
		Return() {
			this.$router.back()
		}
	}
}
</script>

<style lang="less" scoped>
	* {
		margin: 0;
		padding: 0;
	}
	body,html {
		margin: 0;
		padding: 0;
		font-size: 20px;
	}
	
	.txt {
		width: 100%;
		height: 100%;
		background-image: url('../../img/home.png');
		-webkit-touch-callout:none;
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	.head {
		width: 100%;
		height: 7%;
		font-size: 35px;
		color: #fff;
		background-color:rgba(227, 227, 227, 0.5);
		display: flex;
	}
	.headLift {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: left;
	}
	.headCenter {
		width: 40%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.headRight {
		width: 30%;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Body {
		width: 100%;
		height: 90%;
		margin-top: 10px;
		// overflow-y:auto;
	}
	
	// .Body::-webkit-scrollbar {
	// 	display: none;
	// }
	
	.task {
		width: 100%;
		height: 93%;
		overflow-y:auto;
		padding: 30px;
	}
	.task::-webkit-scrollbar {
		display: none;
	}
	
	.foot {
		width: 100%;
		height: 10%;
		color: #d3d3d3;
		display: flex;
	}
</style>